import { useSelector } from "react-redux"
import { RootState } from "../store"
import { AuthenticationSliceState } from "./authenticationSlice"
import { UserOrgPermission } from "src/globalUtils/API"

export const getCurrentSelectedOrganization = (state: AuthenticationSliceState) => {
    return state.loggedInUser?.organizations.find(
        x => x.userOrg.organizationID === state.currentSelectedOrganizationID
    )
}
export const useCurrentSelectedOrganization = () => {
    return useSelector((state: RootState) => getCurrentSelectedOrganization(state.authentication))
}

export const useUserCurrentOrganizationPermission = (): UserOrgPermission | undefined => {
    const org = useSelector((state: RootState) => getCurrentSelectedOrganization(state.authentication))
    return org?.userOrgPermission
}