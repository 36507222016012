import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { CreateKnowledgeBaseCustomMutation, CreateKnowledgeBaseCustomMutationVariables, KnowledgeBase } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export type CreateKnowledgeBaseOutput = {
    organizationID: string;
    createdKnowledgeBase: KnowledgeBase;
}
export const createKnowledgeBase = createAsyncThunk<
    CreateKnowledgeBaseOutput, // output
    {
        createKnowledgeBaseVariables: CreateKnowledgeBaseCustomMutationVariables,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/createKnowledgeBase', async ({ createKnowledgeBaseVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<CreateKnowledgeBaseCustomMutation> = await graphQLClient.graphql<GraphQLQuery<CreateKnowledgeBaseCustomMutation>>({
        query: mutations.createKnowledgeBaseCustom,
        variables: createKnowledgeBaseVariables
    });
    if (response.errors) {
        throw new Error("Failed to create knowledge base")
    }
    return {
        organizationID: createKnowledgeBaseVariables.input.organizationID,
        createdKnowledgeBase: response.data.createKnowledgeBaseCustom
    }
})