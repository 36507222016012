import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { CreateKnowledgeBaseCustomMutation, CreateKnowledgeBaseCustomMutationVariables, KnowledgeBase, UpdateKnowledgeBaseMutation, UpdateKnowledgeBaseMutationVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const updateKnowledgeBase = createAsyncThunk<
    {
        organizationID: string,
        updatedKnowledgeBase: KnowledgeBase,
    }, // output
    {
        updateKnowledgeBaseVariables: UpdateKnowledgeBaseMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/updateKnowledgeBase', async ({ updateKnowledgeBaseVariables }, { dispatch, getState }) => {
    const { currentSelectedOrganizationID } = getState().authentication
    const response: GraphQLResult<UpdateKnowledgeBaseMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateKnowledgeBaseMutation>>({
        query: mutations.updateKnowledgeBase,
        variables: updateKnowledgeBaseVariables
    });
    if (response.errors) {
        throw new Error("Failed to update knowledge base")
    }
    return {
        organizationID: currentSelectedOrganizationID!!,
        updatedKnowledgeBase: response.data.updateKnowledgeBase
    }
})