import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RobotCustomAction, OwnerType, ListRobotCustomActionsByKnowledgeBaseIDQuery, ListRobotCustomActionsByKnowledgeBaseIDQueryVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../store'
import { graphQLClient } from '../../util'
import { getCurrentSelectedOrganization } from '../../authentication/selectedOrganizationHook';
import { updateFetchStatus, updateRobotCustomActionsFetchStatus } from '../robotConciergeSlice';

export const fetchRobotCustomActionsKey = 'fetchRobotCustomActions'
export const fetchRobotCustomActions = createAsyncThunk<
    {
        organizationID: string,
        knowledgeBaseID: string;
        robotCustomActions: RobotCustomAction[]
    } | undefined, // output
    {
        currentOrganizationID: string,
        knowledgeBaseID: string
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchRobotCustomActions', async ({ knowledgeBaseID, currentOrganizationID }, { dispatch, getState }) => {
    const organizationState = getState().robotConcierge.perOrganizationStates?.find(s => s.organizationID === currentOrganizationID)

    if (organizationState?.robotCustomActionsByKnowledgeBaseIdFetchStatus?.[knowledgeBaseID] === 'pending') {
        return undefined;
    }
    dispatch(updateRobotCustomActionsFetchStatus({
        organizationId: currentOrganizationID,
        knowledgeBaseId: knowledgeBaseID,
        status: 'pending'
    }))

    

    // already fetched
    if (organizationState?.robotCustomActionsByKnowledgeBaseId?.[knowledgeBaseID] != null) {
        return undefined
    }

    const robotCustomActions = await fetchRobotCustomActionsHelper({ knowledgeBaseID, state: getState() })
    return {
        organizationID: currentOrganizationID,
        knowledgeBaseID,
        robotCustomActions
    }
})

async function fetchRobotCustomActionsHelper({
    knowledgeBaseID,
    state
}: {
    knowledgeBaseID: string;
    state: RootState
}): Promise<RobotCustomAction[]> {
    let shouldFetch = true
    let nextToken = null
    let robotCustomActions: RobotCustomAction[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListRobotCustomActionsByKnowledgeBaseIDQueryVariables = {
            knowledgeBaseID,
            nextToken
        }
        const response: GraphQLResult<ListRobotCustomActionsByKnowledgeBaseIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListRobotCustomActionsByKnowledgeBaseIDQuery>>({
            query: queries.listRobotCustomActionsByKnowledgeBaseID,
            variables
        });
        nextToken = response.data?.listRobotCustomActionsByKnowledgeBaseID?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        robotCustomActions = robotCustomActions.concat(response.data!!.listRobotCustomActionsByKnowledgeBaseID!!.items as RobotCustomAction[])
    }

    return robotCustomActions
}