import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { DeleteRobotCustomActionsMutation, DeleteRobotCustomActionsMutationVariables, RobotCustomAction } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const deleteRobotCustomActions = createAsyncThunk<
    {
        organizationID: string,
        knowledgeBaseID: string,
        deletedRobotCustomActionIDs: string[],
    }, // output
    {
        knowledgeBaseID: string,
        robotCustomActionIDsToDelete: string[],
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/deleteRobotCustomActions', async ({ knowledgeBaseID, robotCustomActionIDsToDelete }, { dispatch, getState }) => {
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    const variables: DeleteRobotCustomActionsMutationVariables = {
        ids: robotCustomActionIDsToDelete
    }
    const response: GraphQLResult<DeleteRobotCustomActionsMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteRobotCustomActionsMutation>>({
        query: mutations.deleteRobotCustomActions,
        variables
    });
    if (response.errors || !response.data.deleteRobotCustomActions) {
        throw new Error("Failed to Delete custom actions: " + robotCustomActionIDsToDelete)
    }
    return {
        organizationID,
        knowledgeBaseID,
        deletedRobotCustomActionIDs: robotCustomActionIDsToDelete
    }
})