import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { KnowledgeBase, ListKnowledgeBasesByOrgAndOwnerTypeQuery, ListKnowledgeBasesByOrgAndOwnerTypeQueryVariables, OrganizationDataForUser, OrgRole, OwnerType } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../../store'
import { graphQLClient } from '../../util'
import { getCurrentSelectedOrganization } from '../../authentication/selectedOrganizationHook';
import { updateFetchStatus } from '../robotConciergeSlice';

export const fetchKnowledgeBasesKey = 'fetchKnowledgeBases'
export const fetchKnowledgeBases = createAsyncThunk<
    {
        organizationID: string,
        knowledgeBases: KnowledgeBase[]
    } | undefined, // output
    {
        userOrg: OrganizationDataForUser
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchKnowledgeBases', async ({ userOrg }, { dispatch, getState }) => {
    if (getState().robotConcierge.fetchStatus[fetchKnowledgeBasesKey] === 'pending') {
        return undefined;
    }
    dispatch(updateFetchStatus({
        fetchKey: fetchKnowledgeBasesKey,
        status: 'pending'
    }))

    const currentOrganizationID = getState().authentication.currentSelectedOrganizationID!!
    const organizationState = getState().robotConcierge.perOrganizationStates?.find(s => s.organizationID === currentOrganizationID)

    // already fetched
    if (organizationState?.knowledgeBases) {
        return undefined
    }

    // TODO replace with fine grained permission
    const shouldFetchSharedOrgKnowledgeBase = [OrgRole.ADMIN, OrgRole.OWNER].includes(userOrg.userOrg.role)
    return {
        organizationID: currentOrganizationID,
        knowledgeBases: [
            ...await fetchKnowledgeBasesHelper({ currentOrganizationID, ownerType: OwnerType.USERNAME, state: getState() }),
            ...shouldFetchSharedOrgKnowledgeBase ? await fetchKnowledgeBasesHelper({ currentOrganizationID, ownerType: OwnerType.ORGANIZATION, state: getState() })
                : []
        ]
    }
})

async function fetchKnowledgeBasesHelper({
    currentOrganizationID,
    ownerType,
    state
}: {
    currentOrganizationID: string;
    ownerType: OwnerType;
    state: RootState
}): Promise<KnowledgeBase[]> {
    let shouldFetch = true
    let nextToken = null
    let knowledgeBases: KnowledgeBase[] = []
    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const variables: ListKnowledgeBasesByOrgAndOwnerTypeQueryVariables = {
            organizationID: currentOrganizationID,
            ownerType,
            nextToken
        }
        const response: GraphQLResult<ListKnowledgeBasesByOrgAndOwnerTypeQuery> = await graphQLClient.graphql<GraphQLQuery<ListKnowledgeBasesByOrgAndOwnerTypeQuery>>({
            query: queries.listKnowledgeBasesByOrgAndOwnerType,
            variables
        });
        nextToken = response.data?.listKnowledgeBasesByOrgAndOwnerType?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        knowledgeBases = knowledgeBases.concat(response.data!!.listKnowledgeBasesByOrgAndOwnerType!!.items!! as KnowledgeBase[])
    }

    return knowledgeBases
}