import { Stack, Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

// ----------------------------------------------------------------------

type Props = BoxProps & {
  textMessage?: string;
}

export default function LoadingScreen({ sx, textMessage, ...other }: Props) {
  return (
    <Stack spacing={1} alignItems={'center'} justifyContent={'center'}>
      {textMessage && <Typography variant='caption'>{textMessage}</Typography>}
      <Box
        sx={{
          px: 5,
          width: 1,
          flexGrow: 1,
          minHeight: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...sx,
        }}
        {...other}
      >
        <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
      </Box>
    </Stack>
  );
}
