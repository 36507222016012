import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { CreateGenAIAssistantCustomMutation, CreateGenAIAssistantCustomMutationVariables, GenAIAssistant, KnowledgeBase } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export type CreateGenAIAssistantOutput = {
    organizationID: string,
    createdGenAIAssistant: GenAIAssistant,
    updatedKnowledgeBases: KnowledgeBase[]
}
export const createGenAIAssistant = createAsyncThunk<
CreateGenAIAssistantOutput, // output
    {
        createGenAIAssistantVariables: CreateGenAIAssistantCustomMutationVariables,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/createGenAIAssistant', async ({ createGenAIAssistantVariables }, { dispatch, getState }) => {
    const response: GraphQLResult<CreateGenAIAssistantCustomMutation> = await graphQLClient.graphql<GraphQLQuery<CreateGenAIAssistantCustomMutation>>({
        query: mutations.createGenAIAssistantCustom,
        variables: createGenAIAssistantVariables
    });
    if (response.errors) {
        throw new Error("Failed to create knowledge base")
    }
    return {
        organizationID: createGenAIAssistantVariables.input.organizationID,
        createdGenAIAssistant: response.data.createGenAIAssistantCustom.genAIAssistant,
        updatedKnowledgeBases: response.data.createGenAIAssistantCustom.knowledgeBases
    }
})