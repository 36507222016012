import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { DeleteKnowledgeBasesMutation, DeleteKnowledgeBasesMutationVariables, KnowledgeBase } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const deleteKnowledgeBases = createAsyncThunk<
    {
        organizationID: string,
        deletedKnowledgeBaseIDs: string[],
    }, // output
    {
        knowledgeBaseIDsToDelete: string[],
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/deleteKnowledgeBases', async ({ knowledgeBaseIDsToDelete }, { dispatch, getState }) => {
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    const variables: DeleteKnowledgeBasesMutationVariables = {
        ids: knowledgeBaseIDsToDelete
    }
    const response: GraphQLResult<DeleteKnowledgeBasesMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteKnowledgeBasesMutation>>({
        query: mutations.deleteKnowledgeBases,
        variables
    });
    if (response.errors || response.data.deleteKnowledgeBases != null) {
        throw new Error(response.data.deleteKnowledgeBases ?? "Failed to Delete knowledge bases")
    }
    return {
        organizationID,
        deletedKnowledgeBaseIDs: knowledgeBaseIDsToDelete
    }
})