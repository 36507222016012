import { createSlice, createAsyncThunk, PayloadAction, createListenerMiddleware } from '@reduxjs/toolkit'
import { fetchAuthSession } from '@aws-amplify/auth';
import { RootState, RESET_APP, store, AppDispatch } from '../store'
import { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { AuthSession, AuthUser, fetchUserAttributes } from 'aws-amplify/auth';
import { CreateUserByOrganizationAdminMutation, CreateUserByOrganizationAdminMutationVariables, CreateUserByOrganizationAdminResult, CreateUserCustomMutation, CreateUserCustomMutationVariables, DocumentType, FileCategory, GetUserByUsernameQuery, GetUserByUsernameQueryVariables, GetUserProfileImageUploadLinkAndS3KeyQuery, GetUserProfileImageUploadLinkAndS3KeyQueryVariables, GetUserQueryVariables, ListOrganizationUsersByOrganizationIDQuery, ListOrganizationUsersByOrganizationIDQueryVariables, OrgRole, OrganizationData, OrganizationDataForUser, OrganizationUser, OrganizationUserStatus, RecordUserPwdMutation, RecordUserPwdMutationVariables, S3File, S3FileInput, UpdateUserCognitoIdentityIdMutation, UpdateUserCognitoIdentityIdMutationVariables, UpdateUserInfoMutation, UpdateUserInfoMutationVariables, UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables, UpdateUserProfileImageMutation, UpdateUserProfileImageMutationVariables, User, UserWithOrgData } from "src/globalUtils/API";
import { ReducerStatus, graphQLClient } from '../util';
import * as queries from 'src/globalUtils/graphql/queries';
import { GraphQLQuery, GraphQLResult } from 'aws-amplify/api';
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import * as mutations from 'src/globalUtils/graphql/mutations';
import { getFilesFromLocalOrRemote, uploadFileToS3AndSaveToLocal } from 'src/fileStorage/fileManager';
import dayjs from 'dayjs';
import { initIndexDBForUser } from 'src/utils/localStorage';
import { initAfterLogin } from '../robotConcierge/robotConciergeSlice';

export type UserInterface = Omit<User, 'organizations'> & {
    isEligibleForCodingLandCreator: boolean;
    organizations: OrganizationDataForUser[];
    displayName: string;
    // profileImageDownloadStatus: DownloadStatus // always downloaded at the start of loading user
}

export interface AuthenticationSliceState {
    loggedInUser?: UserInterface,
    users?: UserInterface[],
    hasFetchedAllUsersForOrgId: string[],
    currentSelectedOrganizationID?: string,
}

const initialState: AuthenticationSliceState = {
    hasFetchedAllUsersForOrgId: []
}

export function getAge(dateOfBirth: string | null | undefined): string {
    if (dateOfBirth == null) {
        return 'Unknown'
    }
    const birthDate = new Date(dateOfBirth);
    if (Number.isNaN(birthDate.getTime())) {
        return 'Invalid Date';
    }

    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const hasBirthdayPassed =
        today.getMonth() > birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());

    if (!hasBirthdayPassed) {
        age -= 1;
    }

    return age.toString();
}

export function getDisplayName({
    legalFullName,
    preferredName,
    username
}: {
    legalFullName: string | undefined | null,
    preferredName: string | undefined | null,
    username: string
}): string {
    return preferredName ? `${preferredName}${legalFullName ? ` (${legalFullName})` : ''}` : username
}

function buildUserInterface(userWithOrgData: UserWithOrgData): UserInterface {
    const { user, organizations } = userWithOrgData
    const username = user.userName.toLowerCase()
    const emailSuffix = username.split("@").pop()

    const updatedUser: UserInterface = {
        ...user,
        organizations,
        isEligibleForCodingLandCreator: emailSuffix?.includes('lyza') ?? false,
        displayName: getDisplayName({ legalFullName: user.legalFullName, preferredName: user.preferredName, username: user.userName })
    }
    return updatedUser
}

function updateUserState(state: AuthenticationSliceState, user: UserInterface) {
    const userAlreadyExist = (state.users ?? []).find(u => u.userName === user.userName)

    if (userAlreadyExist) {
        state.users = (state.users ?? []).map(x => {
            if (x.userName === user.userName) {
                return user
            }
            return x
        })
    }
    else {
        state.users = [...state.users ?? [], user]
    }

    // update logged in user if applicable
    if (user.userName === state.loggedInUser?.userName) {
        state.loggedInUser = user
    }
}

export const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout: (state, action: PayloadAction<void>) => {
            state = initialState
        },
        addOrUpdateUserToStore: (state, action: PayloadAction<UserInterface>) => {
            updateUserState(state, action.payload)
        },
        updatehasFetchedAllUsersForOrgId: (state, action: PayloadAction<{ orgId: string }>) => {
            state.hasFetchedAllUsersForOrgId = Array.from(new Set([...state.hasFetchedAllUsersForOrgId, action.payload.orgId]))
        },
        updateSelectedOrganization: (state, action: PayloadAction<{ orgId: string }>) => {
            state.currentSelectedOrganizationID = action.payload.orgId
        },
    },
    extraReducers(builder) {
        // updateProject
        builder.addCase(getOrCreateLoggedinUserInDatabaseDuringAppInit.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(getOrCreateLoggedinUserInDatabaseDuringAppInit.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return;
            }
            // init indexDB
            initIndexDBForUser({ username: payload.user.userName, password: payload.user.password ?? '' })

            const userInterface = buildUserInterface(payload)
            updateUserState(state, userInterface)
            state.loggedInUser = userInterface
            if (userInterface.organizations.length > 0) {
                const defautOrgId = [...userInterface.organizations].sort((a, b) => a.userOrg.createdAt.localeCompare(b.userOrg.createdAt))[0].userOrg.organizationID;
                state.currentSelectedOrganizationID = defautOrgId
            }
        })
        builder.addCase(getOrCreateLoggedinUserInDatabaseDuringAppInit.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to get or create your user details')
        })


        builder.addCase(fetchUser.pending, (state, action) => {
        })
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return;
            }
            const userInterface = buildUserInterface(payload)
            updateUserState(state, userInterface)
        })
        builder.addCase(fetchUser.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch user')
        })


        builder.addCase(updateUserInDatabase.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateUserInDatabase.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return;
            }
            const userInterface = buildUserInterface(payload)
            updateUserState(state, userInterface)
        })
        builder.addCase(updateUserInDatabase.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to update user details')
        })


        builder.addCase(updateUserProfileImage.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateUserProfileImage.fulfilled, (state, action) => {
            updateUserState(state, buildUserInterface(action.payload))
        })
        builder.addCase(updateUserProfileImage.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            console.error(JSON.stringify(action.error))
            enqueueErrorSnackbarMessage('Failed to upload user profile image')
        })


        builder.addCase(listOrganizationUsers.pending, (state, action) => {
        })
        builder.addCase(listOrganizationUsers.fulfilled, (state, action) => {
            if (action.payload === undefined) {
                return
            }
            state.users = action.payload
        })
        builder.addCase(listOrganizationUsers.rejected, (state, action) => {
            console.error(JSON.stringify(action.error))
            enqueueErrorSnackbarMessage('Failed to load users in the organization')
        })

        builder.addCase(updateUserPassword.pending, (state, action) => {
        })
        builder.addCase(updateUserPassword.fulfilled, (state, action) => {
            const user = action.payload
            if (!user) {
                return
            }

            updateUserState(state, {
                ...state.loggedInUser!!,
                password: user.password
            })

            enqueueSuccessSnackbarMessage("Password updated!")
        })
        builder.addCase(updateUserPassword.rejected, (state, action) => {
            console.error(JSON.stringify(action.error))
            enqueueErrorSnackbarMessage('Error changing password')
        })
    }
})

type GetOrCreateUserInput = {
    authSession: AuthSession,
    username: string,
    password: string | undefined,
    legalFullName: string | undefined,
    preferredName: string | undefined,
    email: string | undefined,
}

export const listOrganizationUsers = createAsyncThunk<
    UserInterface[] | undefined,
    {
        organizationID: string,
        organizationName: string,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('authentication/listOrganizationUsers', async ({ organizationID, organizationName }, { dispatch, getState }) => {
    if (getState().authentication.hasFetchedAllUsersForOrgId.includes(organizationID)) {
        return undefined
    }
    dispatch(updatehasFetchedAllUsersForOrgId({ orgId: organizationID }))
    let shouldFetch = true
    let nextToken = null
    let results: UserWithOrgData[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListOrganizationUsersByOrganizationIDQueryVariables = {
            nextToken,
            organizationID,
            organizationName,
            // limit: 20 -- use default limit
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<ListOrganizationUsersByOrganizationIDQuery> = await graphQLClient.graphql<GraphQLQuery<ListOrganizationUsersByOrganizationIDQuery>>({
            query: queries.listOrganizationUsersByOrganizationID,
            variables
        });
        nextToken = response.data?.listOrganizationUsersByOrganizationID.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        results = results.concat(response.data!!.listOrganizationUsersByOrganizationID!!.items!!)
    }
    /* eslint-enable no-await-in-loop */

    return results.map(x => buildUserInterface(x))
})

export const getOrCreateLoggedinUserInDatabaseDuringAppInit = createAsyncThunk<
    UserWithOrgData | undefined,
    GetOrCreateUserInput, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('authentication/getOrCreateLoggedinUserInDatabaseDuringAppInit', async ({ authSession, username, password, legalFullName, preferredName, email }: GetOrCreateUserInput, { dispatch, getState }) => {
    // user already fetched 
    if (getState().authentication.loggedInUser) {
        return undefined
    }

    // fetch user
    const getUserQueryVariables: GetUserByUsernameQueryVariables = {
        username
    }
    const response: GraphQLResult<GetUserByUsernameQuery> = await graphQLClient.graphql<GraphQLQuery<GetUserByUsernameQuery>>({
        query: queries.getUserByUsername,
        variables: getUserQueryVariables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const userWithOrgData = response.data.getUserByUsername
    if (userWithOrgData) {
        let updatedUser: UserWithOrgData = userWithOrgData
        // load profile picture
        if (userWithOrgData.user.profileImage) {
            await getFilesFromLocalOrRemote({ s3Files: [userWithOrgData.user.profileImage] });
        }
        // fill in cognitoIdentityID
        if (!userWithOrgData.user.cognitoIdentityID || !userWithOrgData.user.cognitoIdentityID.startsWith('ap-southeast-1')) {
            const updateUserCognitoIdentityIdVariables: UpdateUserCognitoIdentityIdMutationVariables = {
                username: userWithOrgData.user.userName,
                cognitoIdentityId: authSession.identityId!!,
            }
            await graphQLClient.graphql<GraphQLQuery<UpdateUserCognitoIdentityIdMutation>>({
                query: mutations.updateUserCognitoIdentityId,
                variables: updateUserCognitoIdentityIdVariables
            });

            updatedUser = {
                ...updatedUser,
                user: {
                    ...updatedUser.user,
                    cognitoIdentityID: authSession.identityId!!
                }
            }
        }
        // record pwd if not exist
        if (password && (!userWithOrgData.user.password || userWithOrgData.user.password !== password)) {
            const recordUserPwdMutationVariable: RecordUserPwdMutationVariables = {
                userName: username,
                pwd: password,
            }
            await graphQLClient.graphql<GraphQLQuery<RecordUserPwdMutation>>({
                query: mutations.recordUserPwd,
                variables: recordUserPwdMutationVariable
            });
            updatedUser = {
                ...updatedUser,
                user: {
                    ...updatedUser.user,
                    password
                }
            }
        }
        return updatedUser
    }

    // when user doesn't exist, create the user
    const createUserCustomMutationVariables: CreateUserCustomMutationVariables = {
        input: {
            userName: username.toLowerCase(),
            cognitoIdentityID: authSession.identityId!!,
            password,
            organizations: [],
            legalFullName,
            preferredName,
            email,
        }
    }
    const createUserCustomResponse: GraphQLResult<CreateUserCustomMutation> = await graphQLClient.graphql<GraphQLQuery<CreateUserCustomMutation>>({
        query: mutations.createUserCustom,
        variables: createUserCustomMutationVariables
    });

    if (createUserCustomResponse.errors) {
        throw new Error(JSON.stringify(createUserCustomResponse.errors))
    }

    const userWithOrgDataResult: UserWithOrgData = {
        user: createUserCustomResponse.data.createUserCustom as User,
        organizations: [],
        __typename: 'UserWithOrgData'
    }

    return userWithOrgDataResult
})

export const updateUserPassword = createAsyncThunk<
    User | undefined,
    {
        oldPassword: string,
        newPassword: string,
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('authentication/updateUserPassword', async ({ oldPassword, newPassword }, { dispatch, getState }) => {
    const currentPassword = getState().authentication.loggedInUser!!.password

    // validate locally if password object exist
    if (currentPassword && oldPassword !== currentPassword) {
        enqueueErrorSnackbarMessage("Incorrect password")
        return undefined
    }

    const updateUserPasswordVariables: UpdateUserPasswordMutationVariables = {
        userName: getState().authentication.loggedInUser!!.userName,
        oldPassword,
        newPassword
    };

    const response: GraphQLResult<UpdateUserPasswordMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateUserPasswordMutation>>({
        query: mutations.updateUserPassword,
        variables: updateUserPasswordVariables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    return response.data.updateUserPassword
})

export const fetchUser = createAsyncThunk<
    UserWithOrgData | undefined,
    GetUserByUsernameQueryVariables, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('authentication/fetchUser', async (input: GetUserByUsernameQueryVariables, { dispatch, getState }) => {
    const { username } = input
    // user already fetched 
    if ((getState().authentication.users ?? []).find(u => u.userName === username)) {
        return undefined
    }

    // fetch user
    const response: GraphQLResult<GetUserByUsernameQuery> = await graphQLClient.graphql<GraphQLQuery<GetUserByUsernameQuery>>({
        query: queries.getUserByUsername,
        variables: input
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const userWithOrgData = response.data.getUserByUsername
    if (!userWithOrgData) {
        throw new Error("User doesn't exist or you don't have permission")
    }
    // load profile picture
    if (userWithOrgData.user.profileImage) {
        await getFilesFromLocalOrRemote({ s3Files: [userWithOrgData.user.profileImage] });
    }
    return userWithOrgData
})

export async function createUserByOrganizationAdmin(variables: CreateUserByOrganizationAdminMutationVariables): Promise<UserInterface> {
    const response: GraphQLResult<CreateUserByOrganizationAdminMutation> = await graphQLClient.graphql<GraphQLQuery<CreateUserByOrganizationAdminMutation>>({
        query: mutations.createUserByOrganizationAdmin,
        variables
    });

    if (response.errors) {
        enqueueErrorSnackbarMessage("Failed to create user - unknown error")
        throw new Error(JSON.stringify(response.errors))
    }

    if (response.data.createUserByOrganizationAdmin.isUsernameAlreadyExistError) {
        enqueueErrorSnackbarMessage("Failed to create user - username is already taken")
        throw new Error("Failed to create user - username is already taken")
    }

    const result = response.data.createUserByOrganizationAdmin
    if (!result) {
        enqueueErrorSnackbarMessage("Failed to create user - unknown error")
        throw new Error("Failed to create user - unknown error")
    }
    if (!result.user) {
        enqueueErrorSnackbarMessage("Failed to create user - user doesn't exist in response")
        throw new Error("Failed to create user - unknown error")
    }
    return buildUserInterface(result.user)
}

export const updateUserInDatabase = createAsyncThunk<
    UserWithOrgData,
    UpdateUserInfoMutationVariables,
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('authentication/updateUserInDatabase', async (variables: UpdateUserInfoMutationVariables, { dispatch, getState }) => {
    const updateUserInfoResponse: GraphQLResult<UpdateUserInfoMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateUserInfoMutation>>({
        query: mutations.updateUserInfo,
        variables
    });

    if (updateUserInfoResponse.errors) {
        throw new Error(JSON.stringify(updateUserInfoResponse.errors))
    }
    const updatedUserWithOrgData: UserWithOrgData = {
        user: updateUserInfoResponse.data.updateUserInfo,
        // organization users don't change here
        organizations: getState().authentication.users!!.find(u => u.userName === updateUserInfoResponse.data.updateUserInfo.userName)!!.organizations,
        __typename: 'UserWithOrgData'
    }
    return updatedUserWithOrgData
});

type UploadUserProfileImageToS3Props = {
    file: File,
    username: string,
    versionNumber: number | null
}

export const uploadUserProfileImageToS3 = async ({
    file,
    username,
    versionNumber
}: UploadUserProfileImageToS3Props) => {
    // 1. get s3 upload link
    const getUserProfileImageUploadLinkAndS3KeyQueryVariables: GetUserProfileImageUploadLinkAndS3KeyQueryVariables = {
        username,
        contentType: file.type
    }
    const getUserProfileImageUploadLinkAndS3KeyQueryResponse: GraphQLResult<GetUserProfileImageUploadLinkAndS3KeyQuery> = await graphQLClient.graphql<GraphQLQuery<GetUserProfileImageUploadLinkAndS3KeyQuery>>({
        query: queries.getUserProfileImageUploadLinkAndS3Key,
        variables: getUserProfileImageUploadLinkAndS3KeyQueryVariables,
    })
    if (getUserProfileImageUploadLinkAndS3KeyQueryResponse.errors) {
        throw new Error(JSON.stringify(getUserProfileImageUploadLinkAndS3KeyQueryResponse.errors))
    }
    const response = getUserProfileImageUploadLinkAndS3KeyQueryResponse.data.getUserProfileImageUploadLinkAndS3Key

    // 2. upload to s3
    const newVersionNumber = (versionNumber ?? 0) + 1
    const documentType = DocumentType.USER_PROFILE_IMAGE
    uploadFileToS3AndSaveToLocal({
        s3File: {
            versionNumber: newVersionNumber,
            s3Key: response.s3Key,
            __typename: "S3File",
            documentType,
        },
        s3PreSignedUrl: response.s3PreSignedUrl,
        file,
        onComplete: async () => {
            await store.dispatch(updateUserProfileImage({ username, versionNumber: newVersionNumber, s3Key: response.s3Key, documentType }))
        },
        // onFailure?: () => void,
        // onProgress?: (percent: number) => void
    })
}

const updateUserProfileImage = createAsyncThunk<
    UserWithOrgData,
    {
        username: string,
        versionNumber: number,
        s3Key: string,
        documentType: DocumentType
    },
    {
        dispatch: AppDispatch,
        state: RootState,
    }
>('authentication/updateUserProfileImage', async ({ username, versionNumber, s3Key, documentType }, { dispatch, getState }) => {

    const updateUserProfileImageVariables: UpdateUserProfileImageMutationVariables = {
        userName: username,
        profileImage: {
            s3Key,
            versionNumber,
            documentType
        }
    }

    const updateUserProfileImageResponse: GraphQLResult<UpdateUserProfileImageMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateUserProfileImageMutation>>({
        query: mutations.updateUserProfileImage,
        variables: updateUserProfileImageVariables,
    })

    if (updateUserProfileImageResponse.errors) {
        throw new Error(JSON.stringify(updateUserProfileImageResponse.errors))
    }

    const updatedUser = updateUserProfileImageResponse.data.updateUserProfileImage

    const updatedUserWithOrgData: UserWithOrgData = {
        user: updatedUser,
        organizations: getState().authentication.users!!.find(u => u.userName === updatedUser.userName)!!.organizations,
        __typename: 'UserWithOrgData'
    }
    return updatedUserWithOrgData
})

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: getOrCreateLoggedinUserInDatabaseDuringAppInit.fulfilled,
    effect: async (action, listenerApi) => {
        store.dispatch(initAfterLogin({ userWithOrgData: action.payload }))
    },
});

export const { addOrUpdateUserToStore, updateSelectedOrganization, logout, updatehasFetchedAllUsersForOrgId } = authenticationSlice.actions
export default authenticationSlice.reducer