import { PayloadAction, createListenerMiddleware, createSlice } from '@reduxjs/toolkit'
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { CurrentMachineLearningProject, MachineLearningProjectsState, TrainingStatus } from './types';
import { fetchMachineLearningProjects } from './fetchMachineLearningProjects';
import { createMachineLearningProject } from './createMachineLearningProject';
import { DocumentType, MachineLearningModelType, MachineLearningProject, OwnerType } from "src/globalUtils/API";
import { store } from '../store';
import { routeReplaceOrPushPath } from '../router/routerCustomSlice';
import { paths } from 'src/routes/paths';
import { selectMachineLearningProject } from './selectMachineLearningProject';
import { deleteMachineLearningProject } from './deleteMachineLearningProject';
import { v4 } from 'uuid'

const initialState: MachineLearningProjectsState = {
    currentProject: undefined,
    userProjects: undefined,
    perOrganizationProjects: undefined
}

export const machineLearningProjectsSlice = createSlice({
    name: 'machineLearningProjects',
    initialState,
    reducers: {
        updateMachineLearningProject: (state, action: PayloadAction<MachineLearningProject>) => {
            state.userProjects = [
                action.payload,
                ...(state.userProjects ?? []).filter(x =>
                    x.id !== action.payload.id)
            ]
            // update current project
            if (state.currentProject?.project) {
                state.currentProject.project = action.payload
            }
        },
        addNewClass: (state, action: PayloadAction<void>) => {
            const className = `Class ${state.currentProject!!.project.classes.length + 1}`
            state.currentProject!!.project.classes = [...state.currentProject!!.project.classes, {
                name: className,
                __typename: "MachineLearningClass",
                id: v4(), // must use uuid
                // samplesZipS3Key: '',
                samplesZipS3File: {
                    __typename: "S3File",
                    s3Key: '',
                    versionNumber: 0,
                    documentType: DocumentType.MACHINE_LEARNING_CLASS
                },
                isDisabled: false,
            }]
            state.currentProject!!.otherData.classes = [...state.currentProject!!.otherData.classes, {
                images: []
            }]
        },
        deleteClass: (state, action: PayloadAction<{ classID: string }>) => {
            state.currentProject!!.project.classes = state.currentProject!!.project.classes.filter(c => c.id !== action.payload.classID)
        },
        addImageToClass: (state, action: PayloadAction<{ classID: string, image: string }>) => {
            const classIndex = state.currentProject!!.project.classes.findIndex(c => c.id === action.payload.classID)!!
            state.currentProject!!.otherData.classes = state.currentProject!!.otherData.classes.map((c, index) => {
                if (index !== classIndex) {
                    return c
                }
                return {
                    ...c,
                    images: [
                        action.payload.image,
                        ...c.images
                    ]
                }
            })
        },
        deleteImageFromClass: (state, action: PayloadAction<{ classID: string, index: number }>) => {
            const classIndex = state.currentProject!!.project.classes.findIndex(c => c.id === action.payload.classID)!!
            state.currentProject!!.otherData.classes = state.currentProject!!.otherData.classes.map((c, index) => {
                if (classIndex !== index) {
                    return c
                }
                return {
                    ...c,
                    images: c.images.filter((x, idx) => idx !== action.payload.index)
                }
            })
        },
        updateClassName: (state, action: PayloadAction<{ classID: string, name: string }>) => {
            state.currentProject!!.project.classes = state.currentProject!!.project.classes.map(c => {
                if (c.id !== action.payload.classID) {
                    return c
                }
                return {
                    ...c,
                    name: action.payload.name,
                }
            })
        },
        removeAllClassSamples: (state, action: PayloadAction<{ classID: string }>) => {
            const classIndex = state.currentProject!!.project.classes.findIndex(c => c.id === action.payload.classID)!!
            state.currentProject!!.otherData.classes = state.currentProject!!.otherData.classes.map((c, index) => {
                if (index !== classIndex) {
                    return c
                }
                return {
                    ...c,
                    images: []
                }
            })
        },
        toggleClassDisabledStatus: (state, action: PayloadAction<{ classID: string }>) => {
            state.currentProject!!.project.classes = state.currentProject!!.project.classes.map(c => {
                if (c.id !== action.payload.classID) {
                    return c
                }
                return {
                    ...c,
                    isDisabled: !c.isDisabled
                }
            })
        },
        updateTrainingStatus: (state, action: PayloadAction<TrainingStatus>) => {
            state.currentProject!!.trainingStatus = action.payload
        },
    },
    extraReducers(builder) {
        // fetchMachineLearningProjects
        builder.addCase(fetchMachineLearningProjects.pending, (state, action) => {
        })
        builder.addCase(fetchMachineLearningProjects.fulfilled, (state, action) => {
            const payload = action.payload
            // important - otherwise it will update state even if no value is changed
            if (payload === undefined) {
                return
            }
            state.userProjects = payload
        })
        builder.addCase(fetchMachineLearningProjects.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch user Machine Learning models')
        })

        // createMachineLearningProject
        builder.addCase(createMachineLearningProject.pending, (state, action) => {
        })
        builder.addCase(createMachineLearningProject.fulfilled, (state, action) => {
            const project = action.payload.project
            if (project.ownerType === OwnerType.USERNAME) {
                state.userProjects = state.userProjects ? [project, ...state.userProjects] : [project]
            }
            else {
                // TODO
            }

            state.currentProject = action.payload

            // emit succcess
            enqueueSuccessSnackbarMessage('Successfully created Machine Learning model')
        })
        builder.addCase(createMachineLearningProject.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create Machine Learning model')
        })

        // selectMachineLearningProject
        builder.addCase(selectMachineLearningProject.pending, (state, action) => {
        })
        builder.addCase(selectMachineLearningProject.fulfilled, (state, action) => {
            const { project, fetchedFromServer } = action.payload
            state.currentProject = project
            if (!fetchedFromServer) {
                return
            }
            if (project.project.ownerType === OwnerType.USERNAME) {
                state.userProjects = [project.project, ...(state.userProjects ?? [])].sort((left, right) => right!!.updatedAt!!.localeCompare(left!!.updatedAt!!))
            }
            else {
                // TODO for organization
            }
        })
        builder.addCase(selectMachineLearningProject.rejected, (state, action) => {
            enqueueErrorSnackbarMessage(`Machine Learning model doesn't exist or you don't have permission to access`)
        })

        // deleteMachineLearningProject
        builder.addCase(deleteMachineLearningProject.pending, (state, action) => {
        })
        builder.addCase(deleteMachineLearningProject.fulfilled, (state, action) => {
            const { payload } = action
            state.userProjects = (state.userProjects ?? []).filter(x => x.id !== payload.id)


            // emit succcess
            enqueueSuccessSnackbarMessage(`Successfully deleted Machine Learning model ${payload.id}`)
        })
        builder.addCase(deleteMachineLearningProject.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete Machine Learning model')
        })
    }
})

export const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
    actionCreator: createMachineLearningProject.fulfilled,
    effect: async (action, listenerApi) => {
        store.dispatch(selectMachineLearningProject({
            queryVariables: {
                id: action.payload.project.id,
            },
        }))
    },
});

listenerMiddleware.startListening({
    actionCreator: selectMachineLearningProject.rejected,
    effect: async (action, listenerApi) => {
        store.dispatch(routeReplaceOrPushPath(paths.page404))
    }
})

export const { updateMachineLearningProject, updateTrainingStatus, addNewClass, addImageToClass, deleteImageFromClass, updateClassName, deleteClass, removeAllClassSamples, toggleClassDisabledStatus } = machineLearningProjectsSlice.actions

export default machineLearningProjectsSlice.reducer