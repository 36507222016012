import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import MainLayout from 'src/layouts/main';

import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';
import CodeLabWorkspaceLayout from 'src/layouts/codelab-workspace';

// ----------------------------------------------------------------------

// OVERVIEW
const CodelabWorkspacePage = lazy(() => import('src/pages/codelab/workspace/CodelabWorkspacePage'));
const AllProjectsPage = lazy(() => import("src/pages/codelab/allProjects/AllProjectsPage"));
const MachineLearningAllProjectsListViewPage = lazy(() => import('src/pages/codelab/machineLearning/MachineLearningAllProjectsListViewPage'));
const MachineLearningTrainingPage = lazy(() => import('src/pages/codelab/machineLearning/MachineLearningTrainingPage'));
const CodespaceAllProjectsListViewPage = lazy(() => import('src/pages/codelab/codespace/CodespaceAllProjectsListViewPage'))
const CodespaceProjectViewPage = lazy(() => import('src/pages/codelab/codespace/CodespaceProjectViewPage'))

const ScratchHomePage = lazy(() => import('src/pages/codelab/scratch/HomePage'))
const KnowledgeBasesListPage = lazy(() => import('src/pages/codelab/genAI/knowledgebase/KnowledgeBasesListPage'));
const KnowledgeBaseDetailsPage = lazy(() => import('src/pages/codelab/genAI/knowledgebase/KnowledgeBaseDetailsPage'));
const GenAIAssistantsListPage = lazy(() => import('src/pages/codelab/genAI/assistant/GenAIAssistantsListPage'));
const GenAIAssistantDetailsPage = lazy(() => import('src/pages/codelab/genAI/assistant/GenAIAssistantDetailsPage'));
// ----------------------------------------------------------------------

export const codelabRoutes = [
  // CODELAB
  {
    path: paths.codelab.allProjects,
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <AllProjectsPage />, index: true },
    ],
  },
  {
    path: paths.codelab.project(':projectIdUrlParam'),
    element: (
      <AuthGuard>
        <CodeLabWorkspaceLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </CodeLabWorkspaceLayout>
      </AuthGuard>
    ),
    children: [
      { element: <CodelabWorkspacePage />, index: true },
    ],
  },

  // ML
  {
    path: paths.codelab.machineLearning.project(':modelIdUrlParam'),
    element: (
      <AuthGuard>
        <CodeLabWorkspaceLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </CodeLabWorkspaceLayout>
      </AuthGuard>
    ),
    children: [
      { element: <MachineLearningTrainingPage />, index: true },
    ],
  },
  {
    path: paths.codelab.machineLearning.allProjects,
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <MachineLearningAllProjectsListViewPage />, index: true },
    ],
  },

  // codespace
  {
    path: paths.codelab.codespace.allCodespaceProjects,
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <CodespaceAllProjectsListViewPage />, index: true }
    ]
  },
  {
    path: paths.codelab.codespace.projectPath,
    element: (
      <AuthGuard>
        <Suspense fallback={<LoadingScreen />}>
          <Outlet />
        </Suspense>
      </AuthGuard>
    ),
    children: [
      { element: <CodespaceProjectViewPage />, index: true }
    ]
  },

  // Scratch
  {
    path: paths.codelab.scratch.home,
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <ScratchHomePage />, index: true }
    ]
  },

  // GenAI KnowledgeBase
  {
    path: paths.codelab.genAI.knowledgeBase.all,
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <KnowledgeBasesListPage />, index: true }
    ]
  },
  {
    path: paths.codelab.genAI.knowledgeBase.view(':knowledgeBaseIdPathParam'),
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <KnowledgeBaseDetailsPage />, index: true }
    ]
  },

  // GenAI AI Assistant
  {
    path: paths.codelab.genAI.assistant.all,
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { element: <GenAIAssistantsListPage />, index: true }
    ]
  },
  {
    path: paths.codelab.genAI.assistant.view(':genAIAssistantIdPathParam'),
    element: (
      <AuthGuard>
        <CodeLabWorkspaceLayout boxSx={{ overflow: 'hidden' }}>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </CodeLabWorkspaceLayout>
      </AuthGuard>
    ),
    children: [
      { element: <GenAIAssistantDetailsPage />, index: true }
    ]
  },
];
