import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { RobotConciergeState } from './types';
import { fetchConversationHistory, fetchConversationHistoryKey } from './fetchConversationHistory';
import { OrganizationData, OrganizationDataForUser, OrganizationUser, UserWithOrgData } from "src/globalUtils/API";
// import fetchRobotFAQs, { fetchRobotFAQsKey } from './fetchKnowledgeBase';
import { deleteKnowledgeBases } from './knowledgeBase/deleteKnowledgeBases';
import getOrganizationRobots, { getOrganizationRobotsKey } from './getOrganizationRobots';
import { ReducerStatus } from '../util';
import { fetchKnowledgeBases, fetchKnowledgeBasesKey } from './knowledgeBase/fetchKnowledgeBases';
import { createKnowledgeBase } from './knowledgeBase/createKnowledgeBase';
import { updateKnowledgeBase } from './knowledgeBase/updateKnowledgeBase';
import { updateRobotCustomAction } from './robotCustomAction/updateRobotCustomAction';
import { createRobotCustomActions } from './robotCustomAction/createRobotCustomActions';
import { deleteRobotCustomActions } from './robotCustomAction/deleteRobotCustomActions';
import { fetchRobotCustomActions, fetchRobotCustomActionsKey } from './robotCustomAction/fetchRobotCustomActions';
import { fetchGenAIAssistants, fetchGenAIAssistantsKey } from './genaiAssistant/fetchGenAIAssistants';
import { deleteGenAIAssistants } from './genaiAssistant/deleteGenAIAssistants';
import { createGenAIAssistant } from './genaiAssistant/createGenAIAssistant';
import { updateGenAIAssistant } from './genaiAssistant/updateGenAIAssistant';

const initialState: RobotConciergeState = {
    perOrganizationStates: [],
    fetchStatus: {}
}

export const robotConciergeSlice = createSlice({
    name: 'robotConcierge',
    initialState,
    reducers: {
        updateFetchStatus: (state, action: PayloadAction<{ fetchKey: string, status: ReducerStatus }>) => {
            state.fetchStatus = { ...state.fetchStatus, [action.payload.fetchKey]: action.payload.status }
        },
        updateRobotCustomActionsFetchStatus: (state, action: PayloadAction<{ organizationId: string; knowledgeBaseId: string, status: ReducerStatus }>) => {
            const { organizationId, knowledgeBaseId, status } = action.payload
            state.perOrganizationStates = state.perOrganizationStates.map(s => {
                if (s.organizationID !== organizationId) {
                    return s
                }
                return {
                    ...s,
                    robotCustomActionsByKnowledgeBaseIdFetchStatus: {
                        ...s.robotCustomActionsByKnowledgeBaseIdFetchStatus,
                        [knowledgeBaseId]: status
                    }
                }
            })
        },
        initAfterLogin: (state, action: PayloadAction<{ userWithOrgData: UserWithOrgData | undefined }>) => {
            const { userWithOrgData } = action.payload
            if (!userWithOrgData) {
                return
            }
            const existing = state.perOrganizationStates
            const orgIdsNotYetRecorded = userWithOrgData.organizations.filter(o => !existing.map(e => e.organizationID).includes(o.userOrg.organizationID))
            state.perOrganizationStates = [...existing, ...orgIdsNotYetRecorded.map(x => ({
                organizationID: x.userOrg.organizationID
            }))]
        }
    },
    extraReducers(builder) {
        // fetchConversationHistory
        builder.addCase(fetchConversationHistory.pending, (state, action) => {
        })
        builder.addCase(fetchConversationHistory.fulfilled, (state, action) => {
            const payload = action.payload
            // important - otherwise it will update state even if no value is changed
            if (payload === undefined) {
                state.fetchStatus[fetchConversationHistoryKey] = 'fulfilled'
                return
            }
            const stateToChange = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)!!.perRobotStates!!.find(s => s.robotDeviceID === payload.robotDeviceID)!!

            // remove existing
            const newDatesDataToAdd = payload.convoHistoriesToAdd.map(x => x.date)
            const convos = (stateToChange.conversations ?? []).filter(c => !newDatesDataToAdd.includes(c.date))
            // add new
            const afterAppend = [...convos, ...payload.convoHistoriesToAdd]
            // sort desc
            const final = afterAppend.sort((a, b) => b.date.localeCompare(a.date))
            stateToChange.conversations = final

            // emit succcess
            enqueueSuccessSnackbarMessage('Successfully fetched robot conversation history')
            state.fetchStatus[fetchConversationHistoryKey] = 'fulfilled'
        })
        builder.addCase(fetchConversationHistory.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to fetch robot conversation history')
            state.fetchStatus[fetchConversationHistoryKey] = 'rejected'
        })

        // fetchKnowledgeBases
        builder.addCase(fetchKnowledgeBases.pending, (state, action) => {
        })
        builder.addCase(fetchKnowledgeBases.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                state.fetchStatus[fetchKnowledgeBasesKey] = 'fulfilled'
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)
            if (existing) {
                existing.knowledgeBases = payload.knowledgeBases
            }
            else {
                state.perOrganizationStates.push({
                    organizationID: payload.organizationID,
                    knowledgeBases: payload.knowledgeBases
                })
            }
            state.fetchStatus[fetchKnowledgeBasesKey] = 'fulfilled'
        })
        builder.addCase(fetchKnowledgeBases.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to fetch knowledge bases')
            state.fetchStatus[fetchKnowledgeBasesKey] = 'rejected'
        })

        // deleteKnowledgeBases
        builder.addCase(deleteKnowledgeBases.pending, (state, action) => {
        })
        builder.addCase(deleteKnowledgeBases.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.knowledgeBases) {
                existing.knowledgeBases = existing.knowledgeBases.filter(x => !action.payload.deletedKnowledgeBaseIDs.includes(x.id))
            }

            enqueueSuccessSnackbarMessage('Successfully deleted knowledge bases')
        })
        builder.addCase(deleteKnowledgeBases.rejected, (state, action) => {
            enqueueErrorSnackbarMessage(action.error.message ?? 'Failed to delete knowledge bases')
        })

        // createKnowledgeBase
        builder.addCase(createKnowledgeBase.pending, (state, action) => {
        })
        builder.addCase(createKnowledgeBase.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing) {
                existing.knowledgeBases = [...existing.knowledgeBases ?? [], action.payload.createdKnowledgeBase]
            }

            enqueueSuccessSnackbarMessage('Successfully created knowledge base')
        })
        builder.addCase(createKnowledgeBase.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create knowledge base')
        })

        // updateKnowledgeBase
        builder.addCase(updateKnowledgeBase.pending, (state, action) => {
        })
        builder.addCase(updateKnowledgeBase.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.knowledgeBases) {
                existing.knowledgeBases = existing.knowledgeBases.map(k => k.id === action.payload.updatedKnowledgeBase.id ? action.payload.updatedKnowledgeBase : k)
            }

            enqueueSuccessSnackbarMessage('Successfully updated knowledge base')
        })
        builder.addCase(updateKnowledgeBase.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update knowledge base')
        })

        // fetchRobotCustomActions
        builder.addCase(fetchRobotCustomActions.pending, (state, action) => {
        })
        builder.addCase(fetchRobotCustomActions.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)
            if (existing) {
                if (!existing.robotCustomActionsByKnowledgeBaseId) {
                    existing.robotCustomActionsByKnowledgeBaseId = {}
                }
                if (!existing.robotCustomActionsByKnowledgeBaseIdFetchStatus) {
                    existing.robotCustomActionsByKnowledgeBaseIdFetchStatus = {}
                }
                existing.robotCustomActionsByKnowledgeBaseId[payload.knowledgeBaseID] = payload.robotCustomActions
                existing.robotCustomActionsByKnowledgeBaseIdFetchStatus[payload.knowledgeBaseID] = 'fulfilled'
            }
            else {
                state.perOrganizationStates.push({
                    organizationID: payload.organizationID,
                    robotCustomActionsByKnowledgeBaseId: {
                        [payload.knowledgeBaseID]: payload.robotCustomActions
                    },
                    robotCustomActionsByKnowledgeBaseIdFetchStatus: {
                        [payload.knowledgeBaseID]: 'fulfilled'
                    }
                })
            }
        })
        builder.addCase(fetchRobotCustomActions.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to fetch custom actions')
            const { currentOrganizationID, knowledgeBaseID } = action.meta.arg
            state.perOrganizationStates.map(
                s => s.organizationID !== currentOrganizationID ? s
                    : {
                        ...s,
                        robotCustomActionsByKnowledgeBaseIdFetchStatus: {
                            [knowledgeBaseID]: 'rejected'
                        }
                    })
        })

        // deleteRobotCustomActions
        builder.addCase(deleteRobotCustomActions.pending, (state, action) => {
        })
        builder.addCase(deleteRobotCustomActions.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)?.robotCustomActionsByKnowledgeBaseId
            const { knowledgeBaseID } = action.payload
            if (existing?.[knowledgeBaseID]) {
                existing[knowledgeBaseID] = existing[knowledgeBaseID].filter(x => !action.payload.deletedRobotCustomActionIDs.includes(x.id))
            }

            enqueueSuccessSnackbarMessage('Successfully deleted custom actions')
        })
        builder.addCase(deleteRobotCustomActions.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete custom actions')
        })

        // createRobotCustomActions
        builder.addCase(createRobotCustomActions.pending, (state, action) => {
        })
        builder.addCase(createRobotCustomActions.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)?.robotCustomActionsByKnowledgeBaseId
            const knowledgeBaseID = action.payload.createdRobotCustomActions[0].knowledgeBaseID
            if (existing?.[knowledgeBaseID]) {
                existing[knowledgeBaseID] = [...existing[knowledgeBaseID], ...action.payload.createdRobotCustomActions]
            }

            enqueueSuccessSnackbarMessage('Successfully created custom actions')
        })
        builder.addCase(createRobotCustomActions.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create custom actions')
        })

        // updateRobotCustomAction
        builder.addCase(updateRobotCustomAction.pending, (state, action) => {
        })
        builder.addCase(updateRobotCustomAction.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)?.robotCustomActionsByKnowledgeBaseId

            if (existing?.[action.payload.updatedRobotCustomAction.knowledgeBaseID]) {
                existing[action.payload.updatedRobotCustomAction.knowledgeBaseID] = existing[action.payload.updatedRobotCustomAction.knowledgeBaseID].map(k => k.id === action.payload.updatedRobotCustomAction.id ? action.payload.updatedRobotCustomAction : k)
            }

            enqueueSuccessSnackbarMessage('Successfully updated custom action')
        })
        builder.addCase(updateRobotCustomAction.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update custom action')
        })

        // fetchGenAIAssistants
        builder.addCase(fetchGenAIAssistants.pending, (state, action) => {
        })
        builder.addCase(fetchGenAIAssistants.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                state.fetchStatus[fetchGenAIAssistantsKey] = 'fulfilled'
                return
            }
            const existing = state.perOrganizationStates.find(s => s.organizationID === payload.organizationID)
            if (existing) {
                existing.genAIAssistants = payload.genAIAssistants
            }
            else {
                state.perOrganizationStates.push({
                    organizationID: payload.organizationID,
                    genAIAssistants: payload.genAIAssistants
                })
            }
            state.fetchStatus[fetchGenAIAssistantsKey] = 'fulfilled'
        })
        builder.addCase(fetchGenAIAssistants.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch GenAI Assistants')
            state.fetchStatus[fetchGenAIAssistantsKey] = 'rejected'
        })

        // deleteGenAIAssistants
        builder.addCase(deleteGenAIAssistants.pending, (state, action) => {
        })
        builder.addCase(deleteGenAIAssistants.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.genAIAssistants) {
                existing.genAIAssistants = existing.genAIAssistants.filter(x => !action.payload.deletedGenAIAssistantIDs.includes(x.id))
                existing.knowledgeBases = (existing.knowledgeBases ?? []).map(k => {
                    const updated = action.payload.updatedKnowledgeBases.find(k2 => k2.id === k.id)
                    if (updated != null) {
                        return updated
                    }
                    return k
                })
            }

            enqueueSuccessSnackbarMessage('Successfully deleted GenAI Assistants')
        })
        builder.addCase(deleteGenAIAssistants.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to delete GenAI Assistants')
        })

        // createGenAIAssistant
        builder.addCase(createGenAIAssistant.pending, (state, action) => {
        })
        builder.addCase(createGenAIAssistant.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing) {
                existing.genAIAssistants = [...existing.genAIAssistants ?? [], action.payload.createdGenAIAssistant]
                existing.knowledgeBases = (existing.knowledgeBases ?? []).map(k => {
                    const updated = action.payload.updatedKnowledgeBases.find(k2 => k2.id === k.id)
                    if (updated != null) {
                        return updated
                    }
                    return k
                })
            }

            enqueueSuccessSnackbarMessage('Successfully created GenAI Assistant')
        })
        builder.addCase(createGenAIAssistant.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create GenAI Assistant')
        })

        // updateGenAIAssistant
        builder.addCase(updateGenAIAssistant.pending, (state, action) => {
        })
        builder.addCase(updateGenAIAssistant.fulfilled, (state, action) => {
            const existing = state.perOrganizationStates.find(s => s.organizationID === action.payload.organizationID)
            if (existing && existing.genAIAssistants) {
                existing.genAIAssistants = existing.genAIAssistants.map(k => k.id === action.payload.updatedGenAIAssistant.id ? action.payload.updatedGenAIAssistant : k)
                existing.knowledgeBases = (existing.knowledgeBases ?? []).map(k => {
                    const updated = action.payload.updatedKnowledgeBases.find(k2 => k2.id === k.id)
                    if (updated != null) {
                        return updated
                    }
                    return k
                })
            }

            enqueueSuccessSnackbarMessage('Successfully updated GenAI Assistant')
        })
        builder.addCase(updateGenAIAssistant.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update GenAI Assistant')
        })

        // getOrganizationRobots
        builder.addCase(getOrganizationRobots.pending, (state, action) => {
        })
        builder.addCase(getOrganizationRobots.fulfilled, (state, action) => {
            const payload = action.payload
            if (!payload) {
                state.fetchStatus[getOrganizationRobotsKey] = 'fulfilled'
                return
            }
            // update existing
            state.perOrganizationStates = state.perOrganizationStates.map(s => s.organizationID === payload.organizationID
                ? {
                    ...s,
                    perRobotStates: payload.kebbiDevicesForOrganization.map(k => ({
                        robotDeviceID: k.id
                    }))
                }
                : s)
            // add new if existing doesn't exist
            if (state.perOrganizationStates.find(x => x.organizationID === payload.organizationID) == null) {
                state.perOrganizationStates.push({
                    organizationID: payload.organizationID,
                    perRobotStates: payload.kebbiDevicesForOrganization.map(k => ({
                        robotDeviceID: k.id
                    }))
                })
            }
            state.fetchStatus[getOrganizationRobotsKey] = 'fulfilled'
        })
        builder.addCase(getOrganizationRobots.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to load robots')
            state.fetchStatus[getOrganizationRobotsKey] = 'rejected'
        })
    }
})

export const { updateFetchStatus, initAfterLogin, updateRobotCustomActionsFetchStatus } = robotConciergeSlice.actions

export default robotConciergeSlice.reducer