import { push } from '@lagunovsky/redux-react-router'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { BlocklyProject, ListBlocklyProjectsQuery, FileCategory, GetBlocklyProjectByIDQuery, GetBlocklyProjectByIDQueryVariables, CreateBlocklyProjectMutationVariables, CreateBlocklyProjectCustomMutation, CreateBlocklyProjectCustomMutationVariables, FileTypeInput, FileType, ConversationHistory, ListConversationHistoriesQuery, ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery, ListConversationHistoryByDeviceIdAndDateRangeInclusiveQueryVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch, store } from '../store'
import { getLocalDateStringFromDate, getLocalDateStringFromString, getNextDayString, getPreviousDayString } from './util';
import { graphQLClient } from '../util'
import { DailyConversationHistory, PerRobotState, RobotConciergeState } from './types';
import { updateFetchStatus } from './robotConciergeSlice';

type FetchConversationHistoryProps = {
    robotDeviceID: string,
    startDate: string,
    endDate: string
}

function groupAndSortByDate(conversations: ConversationHistory[]): DailyConversationHistory[] {
    const groupedByDate: { [date: string]: ConversationHistory[] } = {};

    conversations.forEach(convo => {
        const localDate = getLocalDateStringFromString(convo.startDateTime);
        if (!groupedByDate[localDate]) {
            groupedByDate[localDate] = [];
        }
        groupedByDate[localDate].push(convo);
    });

    return Object.keys(groupedByDate).map(date => ({
        date,
        dailyConversationsSortedDesc: groupedByDate[date].sort((a, b) => new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime())
    }));
}

function fillMissingDates(conversations: DailyConversationHistory[], start: string, end: string): DailyConversationHistory[] {
    const dateSet = new Set(conversations.map(convo => convo.date));
    const result: DailyConversationHistory[] = [...conversations];
    const endDate = new Date(end)
    for (let date = new Date(start); date <= endDate; date.setDate(date.getDate() + 1)) {
        const dateString = date.toISOString().split('T')[0];
        if (!dateSet.has(dateString)) {
            result.push({
                date: dateString,
                dailyConversationsSortedDesc: []
            });
        }
    }

    return result.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
}

function generateDateRangesToFetch(
    adjustedFetchStartDate: string,
    adjustedFetchEndDate: string,
    earliestFetchedDate: string,
    latestFetchedDate: string
): string[][] {
    const dateRangesToFetch: string[][] = [];
    if (adjustedFetchStartDate >= earliestFetchedDate && adjustedFetchEndDate <= latestFetchedDate) {
        // Do nothing, all data is already fetched
    } else if (adjustedFetchStartDate > latestFetchedDate) {
        dateRangesToFetch.push([adjustedFetchStartDate, adjustedFetchEndDate]);
    } else if (adjustedFetchEndDate < earliestFetchedDate) {
        dateRangesToFetch.push([adjustedFetchStartDate, adjustedFetchEndDate]);
    } else {
        if (adjustedFetchStartDate < earliestFetchedDate) {
            dateRangesToFetch.push([adjustedFetchStartDate, getPreviousDayString(earliestFetchedDate)]);
        }
        if (adjustedFetchEndDate > latestFetchedDate) {
            dateRangesToFetch.push([getNextDayString(latestFetchedDate), adjustedFetchEndDate]);
        }
    }
    return dateRangesToFetch;
}

export const fetchConversationHistoryKey = 'fetchConversationHistory'
export const fetchConversationHistory = createAsyncThunk<
    {
        organizationID: string,
        robotDeviceID: string,
        convoHistoriesToAdd: DailyConversationHistory[],
        startDate: string,
        endDate: string,
    } | undefined,
    FetchConversationHistoryProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/fetchConversationHistory', async ({ robotDeviceID, startDate, endDate }: FetchConversationHistoryProps, { dispatch, getState }) => {
    if (getState().robotConcierge.fetchStatus[fetchConversationHistoryKey] === 'pending') {
        return undefined;
    }
    dispatch(updateFetchStatus({
        fetchKey: fetchConversationHistoryKey,
        status: 'pending'
    }))

    // // Adjust input date range if data already fetched
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    const existingRobotHistory = getState().robotConcierge.perOrganizationStates.find(x => x.organizationID === organizationID)!!.perRobotStates!!.find(x => x.robotDeviceID === robotDeviceID);
    // const adjustedFetchStartDate = getLocalDateStringFromDate(startDate);
    // const adjustedFetchEndDate = getLocalDateStringFromDate(endDate);

    // TODO if existing history covers request range, skip
    if (existingRobotHistory?.conversations) {
        return undefined
    }

    // let dateRangesToFetch: string[][] = [[adjustedFetchStartDate, adjustedFetchEndDate]]
    // if (existingRobotHistory && existingRobotHistory.conversations.length > 0) {
    //     const latestFetchedDate = existingRobotHistory.conversations[0].date;
    //     const earliestFetchedDate = existingRobotHistory.conversations[existingRobotHistory.conversations.length - 1].date;
    //     dateRangesToFetch = generateDateRangesToFetch(adjustedFetchStartDate, adjustedFetchEndDate, earliestFetchedDate, latestFetchedDate)
    // }

    let shouldFetch = true
    let nextToken = null
    let convoHistories: ConversationHistory[] = []

    // temporary hack to always fetch last 30 days data
    const hackEndDate = new Date();
    hackEndDate.setDate(hackEndDate.getDate() + 1);

    const hackStartDate = new Date();
    hackStartDate.setDate(hackStartDate.getDate() - 31);

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListConversationHistoryByDeviceIdAndDateRangeInclusiveQueryVariables = {
            deviceID: robotDeviceID,
            startDate: getLocalDateStringFromDate(hackStartDate),
            endDate: getLocalDateStringFromDate(hackEndDate),
            nextToken
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery> = await graphQLClient.graphql<GraphQLQuery<ListConversationHistoryByDeviceIdAndDateRangeInclusiveQuery>>({
            query: queries.listConversationHistoryByDeviceIdAndDateRangeInclusive,
            variables
        });
        nextToken = response.data?.listConversationHistoryByDeviceIdAndDateRangeInclusive?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        convoHistories = convoHistories.concat(response.data!!.listConversationHistoryByDeviceIdAndDateRangeInclusive!!.items!! as ConversationHistory[])
    }
    /* eslint-enable no-await-in-loop */

    // if (!existingRobotHistory) {
    //     return fillMissingDates([], startDate, endDate);
    // }

    const groupedAndSorted = groupAndSortByDate(convoHistories);
    const dailyConversationsSortedDesc = fillMissingDates(groupedAndSorted, startDate, endDate);

    return {
        organizationID,
        robotDeviceID,
        convoHistoriesToAdd: dailyConversationsSortedDesc,
        startDate,
        endDate
    }
})

export default fetchConversationHistory