import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { AuthGuard } from 'src/auth/guard';
import MainLayout from 'src/layouts/main';

import { LoadingScreen } from 'src/components/loading-screen';
import { paths } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const DashboardPage = lazy(() => import('src/pages/robot-management/dashboard'));

// ----------------------------------------------------------------------
export const robotManagementRoutes = [
  {
    element: (
      <AuthGuard>
        <MainLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </MainLayout>
      </AuthGuard>
    ),
    children: [
      { path: paths.robotManagement.dashboard, element: <DashboardPage /> },
    ],
  },
];
