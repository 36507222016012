import { createAsyncThunk } from '@reduxjs/toolkit'
import { OwnerType, MachineLearningModelType, MachineLearningProject, ListMachineLearningProjectsByIdAndModelTypeQueryVariables, ListMachineLearningProjectsByIdAndModelTypeQuery, UpdateMachineLearningProjectMutationVariables, UpdateMachineLearningProjectMutation, UpdateMachineLearningProjectOutput } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import * as mutations from 'src/globalUtils/graphql/mutations';
import { RootState, AppDispatch, store } from '../store'
import { graphQLClient } from '../util'
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from '../notifications/snackbar-notification-util';
import { updateMachineLearningProject } from './machineLearningProjectsSlice';

export const updateMachineLearningProjectWithoutStore = async ({ id, updatedProjectName, updatedClasses, shouldGenerateFileUploadURL }: UpdateMachineLearningProjectMutationVariables) => {
    try {
        const variables: UpdateMachineLearningProjectMutationVariables = {
            id,
            updatedProjectName,
            updatedClasses,
            shouldGenerateFileUploadURL
        }
        const response: GraphQLResult<UpdateMachineLearningProjectMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateMachineLearningProjectMutation>>({
            query: mutations.updateMachineLearningProject,
            variables
        });

        const data = response.data.updateMachineLearningProject

        if (!data || response.errors) {
            throw new Error("Error updating ML project")
        }

        store.dispatch(updateMachineLearningProject(data.updatedProject))
        // enqueueSuccessSnackbarMessage('Successfully updated Machine Learning model')
        return data
    }
    catch (error) {
        enqueueErrorSnackbarMessage('Failed to create Machine Learning model')
        throw error
    }
}