import { IDBPDatabase, openDB } from 'idb';
import { S3File } from "src/globalUtils/API";

// Open (or create) the database
type InitIndexDBForUserProps = {
  username: string;
  password: string;
}

let indexDB: Promise<IDBPDatabase<unknown>> | null = null;

const VERSION = 3
const OBJECT_STORE_NAME_FILES = 'files'

export function initIndexDBForUser({
  username,
  password
}: InitIndexDBForUserProps) {
  if (indexDB == null) {
    // use password so that it's safer - other users can't access
    indexDB = openDB(`lyza-db-${username}-${password}`, VERSION, {
      upgrade(db, oldVersion, newVersion) {
        if ((newVersion ?? 0) <= 3) {
          if (db.objectStoreNames.contains(OBJECT_STORE_NAME_FILES)) {
            db.deleteObjectStore(OBJECT_STORE_NAME_FILES)
          }
        }

        if (!db.objectStoreNames.contains(OBJECT_STORE_NAME_FILES)) {
          db.createObjectStore(OBJECT_STORE_NAME_FILES, { keyPath: 'key' });
        }
      },
    });
  }
}

type IndexDBFileType = {
  key: string;
  file: File
}

export type StorageFileKey = string

export function buildStorageFileKey(s3File: S3File): StorageFileKey {
  return JSON.stringify({
    key: s3File.s3Key,
    version: s3File.versionNumber
  })
}

// Save a file to IndexedDB
export async function saveFileToIndexedDB(s3File: S3File, file: File) {
  if (indexDB == null) {
    return
  }
  const db = await indexDB;
  const key = buildStorageFileKey(s3File)
  const data: IndexDBFileType = { key, file }
  await db.put(OBJECT_STORE_NAME_FILES, data);
}

// Retrieve a file from IndexedDB
export async function getFileFromIndexedDB(s3File: S3File): Promise<File | undefined> {
  if (indexDB == null) {
    return undefined
  }
  const db = await indexDB;
  const fileKey = buildStorageFileKey(s3File)
  const r: IndexDBFileType | null | undefined = await db.get(OBJECT_STORE_NAME_FILES, fileKey)
  return r?.file;
}

// Remove a file from IndexedDB
export async function removeFileFromIndexedDB(s3Key: string) {
  if (indexDB == null) {
    return
  }
  const db = await indexDB;
  await db.delete(OBJECT_STORE_NAME_FILES, s3Key);
}
