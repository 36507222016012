import { createAsyncThunk, createListenerMiddleware, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { AppDispatch, RootState, store } from "../store"
import * as mutations from 'src/globalUtils/graphql/mutations';
import * as queries from 'src/globalUtils/graphql/queries';
import { enqueueErrorSnackbarMessage, enqueueSuccessSnackbarMessage } from "../notifications/snackbar-notification-util"
import { Centre, ClassObject, ClassStudentRecord, ClassTeacherRecord, Classroom, CreateCentreMutation, CreateCentreMutationVariables, CreateClassForCurriculumMutation, CreateClassForCurriculumMutationVariables, CreateClassInput, CreateClassroomMutation, CreateClassroomMutationVariables, Curriculum, ListCentresQuery, ListCentresQueryVariables, ListClassroomsQuery, ListClassroomsQueryVariables, UpdateCentreMutation, UpdateCentreMutationVariables, UpdateClassroomMutation, UpdateClassroomMutationVariables, CreateClassStudentRecordsMutationVariables, SessionRecordForStudent, SessionRecordForStudentInput, CreateClassStudentRecordsMutation, SessionRecordForTeacherInput, CreateClassTeacherRecordsMutationVariables, TeacherRole, CreateClassTeacherRecordsMutation, UpdateClassStudentRecordsMutationVariables, UpdateClassStudentRecordInput, UpdateClassStudentRecordsMutation, UpdateClassTeacherRecordsMutationVariables, UpdateClassTeacherRecordsMutation, RemoveClassStudentRecordsMutation, RemoveClassStudentRecordInput, RemoveClassStudentRecordsMutationVariables, RemoveClassTeacherRecordsMutationVariables, RemoveClassTeacherRecordsMutation, ClassTeacherRecordWithTeacherDetails, ClassStudentRecordWithStudentDetails, OrgRole, ListClassObjectsWithAttendeeDetailsByUsernameQueryVariables, ListClassObjectsWithAttendeeDetailsByUsernameQuery, ClassObjectWithAttendeeDetails, User, UpdateClassSessionDocumentsMutationVariables, UpdateClassSessionDocumentsMutation, EvaluationItemWithResultsInput, UpdateClassSessionSummaryMutationVariables, UpdateClassSessionSummaryMutation, DocumentType, ScratchAccount, DeleteClassMutationVariables, DeleteClassMutation, UpdateClassSessionsMutation, UpdateClassSessionsMutationVariables, Award } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api";
import { graphQLClient, ReducerStatus } from "../util";
import { createCurriculum, getCurriculumByCurriculumID, listCurriculums, updateCurriculum } from "./curriculumManagement";
import { listOrgAwards } from "./awards";

export type ClassAttendees = {
    students: ClassStudentRecordWithStudentDetails[],
    teachers: ClassTeacherRecordWithTeacherDetails[],
}

export type NotFound = 'not found'

export interface SchoolManagementSliceState {
    // centre
    centreRecordsByOrgId: Record<string, Centre[]>,
    // classroom
    classroomRecordsByOrgId: Record<string, Classroom[]>,
    // class + attendees
    classesByOrgId: Record<string, ClassObjectWithAttendeeDetails[]>,
    // curriculum
    curriculumRecordsByOrgId: Record<string, Curriculum[]>,
    // fetched the current logged in user's all classes
    hasFetchedCurrentUsersAllClasses: ReducerStatus | null,

    // scratch
    scratchAccountsByLyzaUsername: Record<string, ScratchAccount | NotFound>,

    // award
    awardsByOrgId: Record<string, Award[]>,
}

const initialState: SchoolManagementSliceState = {
    // centre
    centreRecordsByOrgId: {},
    // classroom
    classroomRecordsByOrgId: {},
    // class + attendees
    classesByOrgId: {},
    // curriculum
    curriculumRecordsByOrgId: {},
    // fetched the current logged in user's all classes
    hasFetchedCurrentUsersAllClasses: null,

    // scratch
    scratchAccountsByLyzaUsername: {},

    // award
    awardsByOrgId: {}
}

// Function to update the entire centre record for a specific key
const updateCentreRecords = (state: SchoolManagementSliceState, key: string, updates: Centre[]) => {
    state.centreRecordsByOrgId = { ...state.centreRecordsByOrgId, [key]: updates };
};

// Function to update the entire classroom record for a specific key
const updateClassroomRecords = (state: SchoolManagementSliceState, key: string, updates: Classroom[]) => {
    state.classroomRecordsByOrgId = { ...state.classroomRecordsByOrgId, [key]: updates };
};

// Function to update the entire curriculum record for a specific key
const updateCurriculumRecords = (state: SchoolManagementSliceState, key: string, updates: Curriculum[]) => {
    state.curriculumRecordsByOrgId = { ...state.curriculumRecordsByOrgId, [key]: updates };
};

// Function to update the entire curriculum record for a specific key
const updateOrgAwardRecords = (state: SchoolManagementSliceState, orgID: string, updates: Award[]) => {
    state.awardsByOrgId = { ...state.awardsByOrgId, [orgID]: updates };
};

// Function to add a new item update an existing item
const handleAddOrUpdateCentre = (state: SchoolManagementSliceState, key: string, newItem: Centre) => {
    const existingRecord = state.centreRecordsByOrgId[key]?.find(item =>
        item.id === newItem.id
    )
    if (existingRecord) {
        const updatedCentre = state.centreRecordsByOrgId[key].map(item =>
            item.id === newItem.id ? newItem : item
        )
        state.centreRecordsByOrgId = { ...state.centreRecordsByOrgId, [key]: updatedCentre }
    } else {
        const updatedCentre = [...(state.centreRecordsByOrgId[key] || []), newItem];
        state.centreRecordsByOrgId = { ...state.centreRecordsByOrgId, [key]: updatedCentre }
    }
};

// Function to add a new item or update an existing item
const handleAddOrUpdateClassroom = (state: SchoolManagementSliceState, key: string, newItem: Classroom) => {
    const existingRecord = state.classroomRecordsByOrgId[key]?.find(item =>
        item.id === newItem.id
    )
    if (existingRecord) {
        const updatedClassrooms = state.classroomRecordsByOrgId[key].map(item =>
            item.id === newItem.id ? newItem : item
        )
        state.classroomRecordsByOrgId = { ...state.classroomRecordsByOrgId, [key]: updatedClassrooms }
    } else {
        const updatedClassrooms = [...(state.classroomRecordsByOrgId[key] || []), newItem];
        state.classroomRecordsByOrgId = { ...state.classroomRecordsByOrgId, [key]: updatedClassrooms }
    }
};

// Function to add a new item or update an existing item
const handleAddOrUpdateCurriculum = (state: SchoolManagementSliceState, organizationID: string, newItem: Curriculum) => {
    const existingRecord = state.curriculumRecordsByOrgId[organizationID]?.find(item =>
        item.id === newItem.id
    )
    if (existingRecord) {
        const updatedRecords = state.curriculumRecordsByOrgId[organizationID].map(item =>
            item.id === newItem.id ? newItem : item
        )
        state.curriculumRecordsByOrgId = { ...state.curriculumRecordsByOrgId, [organizationID]: updatedRecords }
    } else {
        const updatedRecords = [...(state.curriculumRecordsByOrgId[organizationID] || []), newItem];
        state.curriculumRecordsByOrgId = { ...state.curriculumRecordsByOrgId, [organizationID]: updatedRecords }
    }
};

// Function to add a new item or update an existing item
const handleAddOrUpdateClass = (state: SchoolManagementSliceState, orgId: string, newItem: ClassObjectWithAttendeeDetails) => {
    const existingRecord = state.classesByOrgId[orgId]?.find(item =>
        item.classObject.id === newItem.classObject.id
    )
    if (existingRecord) {
        const updatedClass = state.classesByOrgId[orgId].map(item =>
            item.classObject.id === newItem.classObject.id ? newItem : item
        )
        state.classesByOrgId = { ...state.classesByOrgId, [orgId]: updatedClass }
    } else {
        const updatedClass = [...(state.classesByOrgId[orgId] || []), newItem];
        state.classesByOrgId = { ...state.classesByOrgId, [orgId]: updatedClass }
    }
};

// Function to add or update students to classAttendeeRecords
const handleAddOrUpdateClassStudents = (state: SchoolManagementSliceState, orgId: string, classID: string, updatedStudents: ClassStudentRecordWithStudentDetails[]) => {
    const updatedClasses = state.classesByOrgId[orgId].map(x => x.classObject.id === classID ? {
        ...x,
        students: x.students.map(s => updatedStudents.find(u => u.userDetails.userName === s.userDetails.userName) ?? s)
            .concat(updatedStudents.filter(u => !x.students.map(s => s.userDetails.userName).includes(u.userDetails.userName)))
    } : x)
    state.classesByOrgId = { ...state.classesByOrgId, [orgId]: updatedClasses }
}

// Function to remove students from classAttendeeRecords
const handleRemoveClassStudent = (state: SchoolManagementSliceState, orgId: string, classID: string, usernames: string[]) => {
    const updatedClasses = state.classesByOrgId[orgId].map(x => x.classObject.id === classID ? {
        ...x,
        students: x.students.filter(s => !usernames.includes(s.record.username))
    } : x)
    state.classesByOrgId = { ...state.classesByOrgId, [orgId]: updatedClasses }
}

// Function to add or update teachers to classAttendeeRecords
const handleAddOrUpdateClassTeachers = (state: SchoolManagementSliceState, orgId: string, classID: string, updatedTeachers: ClassTeacherRecordWithTeacherDetails[]) => {
    const updatedClasses = state.classesByOrgId[orgId].map(x => x.classObject.id === classID ? {
        ...x,
        teachers: x.teachers.map(s => updatedTeachers.find(u => u.userDetails.userName === s.userDetails.userName) ?? s)
            .concat(updatedTeachers.filter(u => !x.teachers.map(s => s.userDetails.userName).includes(u.userDetails.userName)))
    } : x)
    state.classesByOrgId = { ...state.classesByOrgId, [orgId]: updatedClasses }
}

// Function to remove students from classAttendeeRecords
const handleRemoveClassTeacher = (state: SchoolManagementSliceState, orgId: string, classID: string, usernames: string[]) => {
    const updatedClasses = state.classesByOrgId[orgId].map(x => x.classObject.id === classID ? {
        ...x,
        teachers: x.teachers.filter(s => !usernames.includes(s.record.username))
    } : x)
    state.classesByOrgId = { ...state.classesByOrgId, [orgId]: updatedClasses }
}

function updateHasFetchedCurrentUsersAllClassesHelper(state: SchoolManagementSliceState, status: ReducerStatus | null) {
    state.hasFetchedCurrentUsersAllClasses = status
}

export const schoolManagementSlice = createSlice({
    name: 'schoolManagement',
    initialState,
    reducers: {
        updateHasFetchedCurrentUsersAllClasses: (state, action: PayloadAction<ReducerStatus | null>) => {
            updateHasFetchedCurrentUsersAllClassesHelper(state, action.payload)
        },
        saveScratchAccounts: (state, action: PayloadAction<{ username: string, account: ScratchAccount | NotFound }[]>) => {
            const accounts = action.payload.reduce((acc, curr) => {
                acc[curr.username] = curr.account
                return acc
            },
                {} as Record<string, ScratchAccount | NotFound>)
            state.scratchAccountsByLyzaUsername = { ...state.scratchAccountsByLyzaUsername, ...accounts }
        },
        deleteClassFromOrganization: (state, action: PayloadAction<{ organizationID: string, classIdToDelete: string }>) => {
            state.classesByOrgId[action.payload.organizationID] = state.classesByOrgId[action.payload.organizationID].filter(c => c.classObject.id !== action.payload.classIdToDelete)
        }
    },
    extraReducers(builder) {
        // get class attendees by username
        builder.addCase(fetchCurrentUsersAllClasses.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(fetchCurrentUsersAllClasses.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }
            payload.classesWithAttendeeDetails.forEach(c => {
                handleAddOrUpdateClass(state, payload.organizationID, c)
            })
            updateHasFetchedCurrentUsersAllClassesHelper(state, 'fulfilled')
        })
        builder.addCase(fetchCurrentUsersAllClasses.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to get class')
            updateHasFetchedCurrentUsersAllClassesHelper(state, 'rejected')
        })

        // create class
        builder.addCase(createClass.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(createClass.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            handleAddOrUpdateClass(state, payload.organizationID, {
                __typename: "ClassObjectWithAttendeeDetails",
                classObject: payload.class,
                students: [],
                teachers: []
            })
        })
        builder.addCase(createClass.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to create class')
        })

        // update classes session documents
        builder.addCase(updateClassSessionDocuments.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateClassSessionDocuments.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            const existingClass = state.classesByOrgId[payload.organizationID].find(c => c.classObject.id === payload.class.id)!!
            handleAddOrUpdateClass(state, payload.organizationID, {
                __typename: "ClassObjectWithAttendeeDetails",
                classObject: payload.class,
                students: existingClass.students,
                teachers: existingClass.teachers
            })
            enqueueSuccessSnackbarMessage('Successfully updated session documents')
        })
        builder.addCase(updateClassSessionDocuments.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to update session documents')
        })

        // update classes sessions
        builder.addCase(updateClassSessions.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateClassSessions.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            const existingClass = state.classesByOrgId[payload.organizationID].find(c => c.classObject.id === payload.class.id)!!
            handleAddOrUpdateClass(state, payload.organizationID, {
                __typename: "ClassObjectWithAttendeeDetails",
                classObject: payload.class,
                students: existingClass.students,
                teachers: existingClass.teachers
            })
            enqueueSuccessSnackbarMessage('Successfully updated sessions')
        })
        builder.addCase(updateClassSessions.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update sessions')
        })

        // update classes session summary
        builder.addCase(updateClassSessionSummary.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateClassSessionSummary.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            const existingClass = state.classesByOrgId[payload.organizationID].find(c => c.classObject.id === payload.class.id)!!
            handleAddOrUpdateClass(state, payload.organizationID, {
                __typename: "ClassObjectWithAttendeeDetails",
                classObject: payload.class,
                students: existingClass.students,
                teachers: existingClass.teachers
            })
            enqueueSuccessSnackbarMessage('Successfully updated session summary')
        })
        builder.addCase(updateClassSessionSummary.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to update session summary')
        })

        // list centres
        builder.addCase(listCentresByOrgId.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(listCentresByOrgId.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }
            updateCentreRecords(state, payload.organizationID, payload.centres)
        })
        builder.addCase(listCentresByOrgId.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to list centre')
        })

        // create centre
        builder.addCase(createCentre.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(createCentre.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            handleAddOrUpdateCentre(state, payload.organizationID, payload.centre)
        })
        builder.addCase(createCentre.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to create centre')
        })

        // update centre
        builder.addCase(updateCentre.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateCentre.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            handleAddOrUpdateCentre(state, payload.organizationID, payload.centre)
        })
        builder.addCase(updateCentre.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to update centre')
        })

        // list classrooms
        builder.addCase(listClassroomsByOrgId.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(listClassroomsByOrgId.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            updateClassroomRecords(state, payload.organizationID, payload.classrooms)
        })
        builder.addCase(listClassroomsByOrgId.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to list classroom')
        })

        // create classroom
        builder.addCase(createClassroom.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(createClassroom.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            handleAddOrUpdateClassroom(state, payload.organizationID, payload.classroom)
        })
        builder.addCase(createClassroom.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to create classroom')
        })

        // update classroom
        builder.addCase(updateClassroom.pending, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
        })
        builder.addCase(updateClassroom.fulfilled, (state, action) => {
            const payload = action.payload
            if (payload === undefined) {
                return
            }

            handleAddOrUpdateClassroom(state, payload.organizationID, payload.classroom)
        })
        builder.addCase(updateClassroom.rejected, (state, action) => {
            // TODO add a status state for loading. See example https://redux.js.org/tutorials/essentials/part-5-async-logic#reducers-and-loading-actions
            enqueueErrorSnackbarMessage('Failed to update classroom')
        })

        // create curriculum
        builder.addCase(createCurriculum.pending, (state, action) => {
        })
        builder.addCase(createCurriculum.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }

            handleAddOrUpdateCurriculum(state, payload.organizationID, payload.curriculum)
            enqueueSuccessSnackbarMessage('Successfully created curriculum')
        })
        builder.addCase(createCurriculum.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to create curriculum')
        })

        // update curriculum data
        builder.addCase(updateCurriculum.pending, (state, action) => {
        })
        builder.addCase(updateCurriculum.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }

            handleAddOrUpdateCurriculum(state, payload.organizationID, payload.updatedCurriculum)

            // update existing classObjects
            const updatedClasses = (state.classesByOrgId[payload.organizationID] ?? []).map(classObj => {
                const updatedClass = payload.updatedClasses.find(u => u.updatedClass.id === classObj.classObject.id)
                if (updatedClass != null) {
                    const updated: ClassObjectWithAttendeeDetails = {
                        __typename: "ClassObjectWithAttendeeDetails",
                        classObject: updatedClass.updatedClass,
                        students: classObj.students.map(s => {
                            const updatedStudentRecord = updatedClass.updatedClassStudentRecords.find(x => x.id === s.record.id)
                            if (updatedStudentRecord != null) {
                                return {
                                    __typename: "ClassStudentRecordWithStudentDetails",
                                    record: updatedStudentRecord,
                                    userDetails: s.userDetails,
                                }
                            }
                            return s
                        }),
                        teachers: classObj.teachers,
                    }
                    return updated
                }
                return classObj
            })
            state.classesByOrgId = { ...state.classesByOrgId, [payload.organizationID]: updatedClasses }

            // toast
            enqueueSuccessSnackbarMessage('Successfully updated curriculum')
        })
        builder.addCase(updateCurriculum.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update curriculum')
        })

        // add student to class
        builder.addCase(addStudentsToClass.pending, (state, action) => {
        })
        builder.addCase(addStudentsToClass.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            handleAddOrUpdateClassStudents(state, payload.organizationID, payload.classID, payload.students)
            enqueueSuccessSnackbarMessage("Successfully added student(s) to class")
        })
        builder.addCase(addStudentsToClass.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to add students to class')
        })

        // update student in class
        builder.addCase(updateStudentsInClass.pending, (state, action) => {
        })
        builder.addCase(updateStudentsInClass.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            handleAddOrUpdateClassStudents(state, payload.organizationID, payload.classID, payload.students)
            enqueueSuccessSnackbarMessage("Successfully updated student record")
        })
        builder.addCase(updateStudentsInClass.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update students in class')
        })

        // remove student from class
        builder.addCase(removeStudentsFromClass.pending, (state, action) => {
        })
        builder.addCase(removeStudentsFromClass.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            handleRemoveClassStudent(state, payload.organizationID, payload.classID, payload.studentUsernames)
            enqueueSuccessSnackbarMessage("Successfully removed student(s) from class")
        })
        builder.addCase(removeStudentsFromClass.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to remove students from class')
        })

        // add teacher to class
        builder.addCase(addTeachersToClass.pending, (state, action) => {
        })
        builder.addCase(addTeachersToClass.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            handleAddOrUpdateClassTeachers(state, payload.organizationID, payload.classID, payload.teachers)
            enqueueSuccessSnackbarMessage("Successfully added teacher(s) to class")
        })
        builder.addCase(addTeachersToClass.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to add teachers to class')
        })

        // update teacher in class
        builder.addCase(updateTeachersInClass.pending, (state, action) => {
        })
        builder.addCase(updateTeachersInClass.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            handleAddOrUpdateClassTeachers(state, payload.organizationID, payload.classID, payload.teachers)
            enqueueSuccessSnackbarMessage("Successfully updated teacher records in class")
        })
        builder.addCase(updateTeachersInClass.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to update teachers in class')
        })

        // remove teacher from class
        builder.addCase(removeTeachersFromClass.pending, (state, action) => {
        })
        builder.addCase(removeTeachersFromClass.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            handleRemoveClassTeacher(state, payload.organizationID, payload.classID, payload.teacherUsernames)
            enqueueSuccessSnackbarMessage("Successfully removed teacher(s) from class")
        })
        builder.addCase(removeTeachersFromClass.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to remove teachers from class')
        })

        // list curriculums
        builder.addCase(listCurriculums.pending, (state, action) => {
        })
        builder.addCase(listCurriculums.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            updateCurriculumRecords(state, payload.organizationID, payload.curriculums)
        })
        builder.addCase(listCurriculums.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch curriculums')
        })

        // listOrgAwards
        builder.addCase(listOrgAwards.pending, (state, action) => {
        })
        builder.addCase(listOrgAwards.fulfilled, (state, action) => {
            const { payload } = action
            if (payload === undefined) {
                return
            }
            updateOrgAwardRecords(state, payload.organizationID, payload.awards)
        })
        builder.addCase(listOrgAwards.rejected, (state, action) => {
            enqueueErrorSnackbarMessage('Failed to fetch awards')
        })
    }
})

export const fetchCurrentUsersAllClasses = createAsyncThunk<
    {
        organizationID: string,
        classesWithAttendeeDetails: ClassObjectWithAttendeeDetails[]
    } | undefined,
    {
        organizationID: string,
        username: string,
        role: OrgRole
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/fetchCurrentUsersAllClasses', async ({ organizationID, username, role }, { dispatch, getState }) => {
    // is fetching or fetched
    if (getState().schoolManagement.hasFetchedCurrentUsersAllClasses != null) {
        return undefined
    }

    dispatch(updateHasFetchedCurrentUsersAllClasses('pending'))
    let shouldFetch = true
    let nextToken = null

    let classesWithAttendeeDetails: ClassObjectWithAttendeeDetails[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListClassObjectsWithAttendeeDetailsByUsernameQueryVariables = {
            username,
            role,
            orgId: organizationID
        }
        const response: GraphQLResult<ListClassObjectsWithAttendeeDetailsByUsernameQuery> = await graphQLClient.graphql<GraphQLQuery<ListClassObjectsWithAttendeeDetailsByUsernameQuery>>({
            query: queries.listClassObjectsWithAttendeeDetailsByUsername,
            variables
        });
        nextToken = response.data.listClassObjectsWithAttendeeDetailsByUsername.nextToken
        const items: ClassObjectWithAttendeeDetails[] = response.data.listClassObjectsWithAttendeeDetailsByUsername.items
        shouldFetch = nextToken != null
        classesWithAttendeeDetails = classesWithAttendeeDetails.concat(items)
    }

    return { organizationID, classesWithAttendeeDetails }
})

export const createClass = createAsyncThunk<
    {
        organizationID: string,
        class: ClassObject
    } | undefined,
    {
        organizationID: string,
        createClassInput: CreateClassInput,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/createClass', async ({ organizationID, createClassInput }, { dispatch, getState }) => {
    const variables: CreateClassForCurriculumMutationVariables = {
        createClassInput,
    }
    const response: GraphQLResult<CreateClassForCurriculumMutation> = await graphQLClient.graphql<GraphQLQuery<CreateClassForCurriculumMutation>>({
        query: mutations.createClassForCurriculum,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const classObj = response.data.createClassForCurriculum!
    return { organizationID, class: classObj }
})

export const updateClassSessions = createAsyncThunk<
    {
        organizationID: string,
        class: ClassObject
    } | undefined,
    {
        organizationID: string,
        variables: UpdateClassSessionsMutationVariables
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateClassSessions', async ({ organizationID, variables }, { dispatch, getState }) => {
    const response: GraphQLResult<UpdateClassSessionsMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateClassSessionsMutation>>({
        query: mutations.updateClassSessions,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const result = response.data.updateClassSessions
    if (result.actionableErrorMsg) {
        enqueueErrorSnackbarMessage(result.actionableErrorMsg)
        return undefined
    }
    return { organizationID, class: result.classObject!! }
})

export const updateClassSessionDocuments = createAsyncThunk<
    {
        organizationID: string,
        class: ClassObject
    } | undefined,
    {
        organizationID: string,
        variables: UpdateClassSessionDocumentsMutationVariables
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateClassSessionDocuments', async ({ organizationID, variables }, { dispatch, getState }) => {
    const response: GraphQLResult<UpdateClassSessionDocumentsMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateClassSessionDocumentsMutation>>({
        query: mutations.updateClassSessionDocuments,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const result = response.data.updateClassSessionDocuments
    if (result.actionableErrorMsg) {
        enqueueErrorSnackbarMessage(result.actionableErrorMsg)
        return undefined
    }
    return { organizationID, class: result.classObject!! }
})

export const updateClassSessionSummary = createAsyncThunk<
    {
        organizationID: string,
        class: ClassObject
    } | undefined,
    {
        organizationID: string,
        variables: UpdateClassSessionSummaryMutationVariables
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateClassSessionSummary', async ({ organizationID, variables }, { dispatch, getState }) => {
    const response: GraphQLResult<UpdateClassSessionSummaryMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateClassSessionSummaryMutation>>({
        query: mutations.updateClassSessionSummary,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const result = response.data.updateClassSessionSummary
    if (result.actionableErrorMsg) {
        enqueueErrorSnackbarMessage(result.actionableErrorMsg)
        return undefined
    }
    return { organizationID, class: result.classObject!! }
})

export const deleteClass = async ({ organizationID, variables }: {
    organizationID: string,
    variables: DeleteClassMutationVariables
}): Promise<{ errorMsg: string | undefined }> => {
    const response: GraphQLResult<DeleteClassMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteClassMutation>>({
        query: mutations.deleteClass,
        variables
    });

    if (response.errors) {
        return {
            errorMsg: 'Failed to delete the class'
        }
    }

    const result = response.data.deleteClass
    if (result.errorMessage) {
        return {
            errorMsg: result.errorMessage
        }
    }
    store.dispatch(deleteClassFromOrganization({
        organizationID,
        classIdToDelete: variables.classID
    }))
    return {
        errorMsg: undefined
    }
}

type MutateClassStudentRequest = {
    username: string;
    sessionsAssignedStatus: boolean[];
}

export const addStudentsToClass = createAsyncThunk<
    {
        organizationID: string,
        classID: string,
        students: ClassStudentRecordWithStudentDetails[]
    } | undefined,
    {
        organizationID: string,
        classID: string,
        studentsToAdd: MutateClassStudentRequest[]
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/addStudentsToClass', async ({ organizationID, classID, studentsToAdd }, { dispatch, getState }) => {
    const classData = getState().schoolManagement.classesByOrgId[organizationID].find(x => x.classObject.id === classID)!!
    const endOfClassEvaluations: Array<EvaluationItemWithResultsInput> = classData.classObject.curriculum.endOfClassEvaluationsTemplate.map(x => ({
        metric: x.metric,
        results: x.formats.map(f => ({
            format: f.format,
            required: f.required,
            result: null
        }))
    }))
    const variables: CreateClassStudentRecordsMutationVariables = {
        createClassStudentRecordInputs: studentsToAdd.map((studentToAdd) => ({
            username: studentToAdd.username,
            classID,
            organizationID,
            sessionRecords: classData.classObject.sessions.map((s, index) => ({
                isAssigned: studentToAdd.sessionsAssignedStatus[index],
                attended: null,
                notAttendedReason: null,
                sessionEvaluations: classData.classObject.curriculum.sessionTemplates[index].evaluationItems.map(x => ({
                    metric: x.metric,
                    results: x.formats.map(f => ({
                        format: f.format,
                        required: f.required,
                        result: null
                    }))
                })),
                sentCommunications: [],
                documents: [],
            })),
            endOfClassPhotoOrVideos: [],
            sentClassLevelCommunications: [],
            endOfClassEvaluations
        })),
    }
    const response: GraphQLResult<CreateClassStudentRecordsMutation> = await graphQLClient.graphql<GraphQLQuery<CreateClassStudentRecordsMutation>>({
        query: mutations.createClassStudentRecords,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const students = response.data.createClassStudentRecords!
    return {
        organizationID,
        classID,
        students: students.map(x => {
            const {
                isEligibleForCodingLandCreator,
                organizations,
                displayName,
                ...user
            } = getState().authentication.users!!.find(u => u.userName === x.username)!!
            return {
                __typename: "ClassStudentRecordWithStudentDetails",
                record: x,
                userDetails: user as User
            }
        })
    }
})

type UpdateStudentsInClassResult = {
    organizationID: string,
    classID: string,
    students: ClassStudentRecordWithStudentDetails[],
}

export const updateStudentsInClass = createAsyncThunk<
    UpdateStudentsInClassResult | undefined,
    {
        organizationID: string,
        classID: string,
        variables: UpdateClassStudentRecordsMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateStudentsInClass', async ({ organizationID, classID, variables }, { dispatch, getState }) => {
    const response: GraphQLResult<UpdateClassStudentRecordsMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateClassStudentRecordsMutation>>({
        query: mutations.updateClassStudentRecords,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const updateClassStudentRecordsData = response.data.updateClassStudentRecords

    // handle error with user friendly error message from server
    if (updateClassStudentRecordsData.errorReasonForUser) {
        enqueueErrorSnackbarMessage(updateClassStudentRecordsData.errorReasonForUser)
        return undefined
    }

    const existingClassStudentRecords = getState().schoolManagement.classesByOrgId[organizationID].find(c => c.classObject.id === classID)!!.students
    const result: UpdateStudentsInClassResult = {
        organizationID,
        classID,
        students: updateClassStudentRecordsData.results.map(x => {
            return {
                __typename: "ClassStudentRecordWithStudentDetails",
                record: x,
                userDetails: existingClassStudentRecords.find(s => s.record.username === x.username)!!.userDetails!!
            }
        })
    }
    return result
})

export const removeStudentsFromClass = createAsyncThunk<
    {
        organizationID: string,
        classID: string,
        studentUsernames: string[]
    } | undefined,
    {
        organizationID: string,
        classID: string,
        classStudentRecordIdsToRemove: string[]
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/removeStudentsFromClass', async ({ organizationID, classID, classStudentRecordIdsToRemove }, { dispatch, getState }) => {
    const variables: RemoveClassStudentRecordsMutationVariables = {
        removeClassStudentRecordInputs: classStudentRecordIdsToRemove.map((id) => ({
            id,
        }))
    }

    const studentUsernames = getState().schoolManagement.classesByOrgId[organizationID]
        .find(x => x.classObject.id === classID)!!
        .students
        .filter(t => classStudentRecordIdsToRemove.includes(t.record.id))
        .map(x => x.userDetails.userName)

    const response: GraphQLResult<RemoveClassStudentRecordsMutation> = await graphQLClient.graphql<GraphQLQuery<RemoveClassStudentRecordsMutation>>({
        query: mutations.removeClassStudentRecords,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    return { organizationID, classID, studentUsernames }
})

export type MutateTeacherRequest = {
    username: string;
    sessionsRole: (TeacherRole | undefined)[];
}
export const addTeachersToClass = createAsyncThunk<
    {
        organizationID: string,
        classID: string,
        teachers: ClassTeacherRecordWithTeacherDetails[]
    } | undefined,
    {
        organizationID: string,
        classID: string,
        teachersToAdd: MutateTeacherRequest[]
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/addTeachersToClass', async ({ organizationID, classID, teachersToAdd }, { dispatch, getState }) => {
    const variables: CreateClassTeacherRecordsMutationVariables = {
        createClassTeacherRecordInputs: teachersToAdd.map((teacherToAdd) => ({
            username: teacherToAdd.username,
            classID,
            organizationID,
            sessionRecords: teacherToAdd.sessionsRole.map((role) => {
                if (role == null) {
                    return null
                }
                return {
                    role,
                    documents: []
                }
            })
        }))
    }
    const response: GraphQLResult<CreateClassTeacherRecordsMutation> = await graphQLClient.graphql<GraphQLQuery<CreateClassTeacherRecordsMutation>>({
        query: mutations.createClassTeacherRecords,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const teachers = response.data.createClassTeacherRecords!
    return {
        organizationID,
        classID,
        teachers: teachers.map(x => {
            const {
                isEligibleForCodingLandCreator,
                organizations,
                displayName,
                ...user
            } = getState().authentication.users!!.find(u => u.userName === x.username)!!
            return {
                __typename: "ClassTeacherRecordWithTeacherDetails",
                record: x,
                userDetails: user
            }
        })
    }
})

export const updateTeachersInClass = createAsyncThunk<
    {
        organizationID: string,
        classID: string,
        teachers: ClassTeacherRecordWithTeacherDetails[],
    } | undefined,
    {
        organizationID: string,
        classID: string,
        variables: UpdateClassTeacherRecordsMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateTeacherInClass', async ({ organizationID, classID, variables }, { dispatch, getState }) => {
    const response: GraphQLResult<UpdateClassTeacherRecordsMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateClassTeacherRecordsMutation>>({
        query: mutations.updateClassTeacherRecords,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const updateClassTeacherRecordsData = response.data.updateClassTeacherRecords

    // handle error with user friendly error message from server
    if (updateClassTeacherRecordsData.errorReasonForUser) {
        enqueueErrorSnackbarMessage(updateClassTeacherRecordsData.errorReasonForUser)
        return undefined
    }

    return {
        organizationID,
        classID,
        teachers: updateClassTeacherRecordsData.results.map(x => {
            const {
                isEligibleForCodingLandCreator,
                organizations,
                displayName,
                ...user
            } = getState().authentication.users!!.find(u => u.userName === x.username)!!
            return {
                __typename: "ClassTeacherRecordWithTeacherDetails",
                record: x,
                userDetails: user
            }
        })
    }
})

export const removeTeachersFromClass = createAsyncThunk<
    {
        organizationID: string,
        classID: string,
        teacherUsernames: string[],
    } | undefined,
    {
        organizationID: string,
        classID: string,
        classTeacherRecordIdsToRemove: string[]
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/removeTeachersFromClass', async ({ organizationID, classID, classTeacherRecordIdsToRemove }, { dispatch, getState }) => {
    const variables: RemoveClassTeacherRecordsMutationVariables = {
        removeClassTeacherRecordInputs: classTeacherRecordIdsToRemove.map(id => ({
            id
        }))
    }

    const teacherUsernames = getState().schoolManagement.classesByOrgId[organizationID]
        .find(x => x.classObject.id === classID)!!
        .teachers
        .filter(t => classTeacherRecordIdsToRemove.includes(t.record.id))
        .map(x => x.userDetails.userName)

    const response: GraphQLResult<RemoveClassTeacherRecordsMutation> = await graphQLClient.graphql<GraphQLQuery<RemoveClassTeacherRecordsMutation>>({
        query: mutations.removeClassTeacherRecords,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    return { organizationID, classID, teacherUsernames }
})

export const listCentresByOrgId = createAsyncThunk<
    {
        organizationID: string,
        centres: Centre[]
    } | undefined,
    {
        organizationID: string,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/listCentresByOrgId', async ({ organizationID }, { dispatch, getState }) => {
    if (getState().schoolManagement.centreRecordsByOrgId[organizationID]) {
        return undefined
    }
    let shouldFetch = true
    let nextToken = null
    let centres: Centre[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListCentresQueryVariables = {
            nextToken,
            organizationID,
            // limit: 20 -- use default limit
        }
        const response: GraphQLResult<ListCentresQuery> = await graphQLClient.graphql<GraphQLQuery<ListCentresQuery>>({
            query: queries.listCentres,
            variables
        });
        nextToken = response.data?.listCentres?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        centres = centres.concat(response.data!!.listCentres!!.items!!)
    }
    /* eslint-enable no-await-in-loop */

    return { organizationID, centres }
})

export const createCentre = createAsyncThunk<
    {
        organizationID: string,
        centre: Centre,
    },
    {
        mutationVariables: CreateCentreMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/createCentre', async ({ mutationVariables }, { dispatch, getState }) => {
    const { organizationID, data, status } = mutationVariables

    const variables = {
        organizationID,
        data,
        status
    }

    const response: GraphQLResult<CreateCentreMutation> = await graphQLClient.graphql<GraphQLQuery<CreateCentreMutation>>({
        query: mutations.createCentre,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const centre = response.data.createCentre
    return { organizationID, centre }
})

export const updateCentre = createAsyncThunk<
    {
        organizationID: string,
        centre: Centre,
    },
    {
        organizationID: string,
        mutationVariables: UpdateCentreMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateCentre', async ({ organizationID, mutationVariables }, { dispatch, getState }) => {
    const { id, data, status } = mutationVariables

    const variables = {
        id,
        data,
        status
    }

    const response: GraphQLResult<UpdateCentreMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateCentreMutation>>({
        query: mutations.updateCentre,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const centre = response.data.updateCentre
    return { organizationID, centre }
})

export const listClassroomsByOrgId = createAsyncThunk<
    {
        organizationID: string,
        classrooms: Classroom[]
    } | undefined,
    {
        organizationID: string,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/listClassroomsByOrgId', async ({ organizationID }, { dispatch, getState }) => {
    if (getState().schoolManagement.classroomRecordsByOrgId[organizationID]) {
        return undefined
    }
    let shouldFetch = true
    let nextToken = null
    let classrooms: Classroom[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListClassroomsQueryVariables = {
            nextToken,
            organizationID,
            // limit: 20 -- use default limit
        }
        const response: GraphQLResult<ListClassroomsQuery> = await graphQLClient.graphql<GraphQLQuery<ListClassroomsQuery>>({
            query: queries.listClassrooms,
            variables
        });
        nextToken = response.data?.listClassrooms?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        classrooms = classrooms.concat(response.data!!.listClassrooms!!.items!!)
    }
    /* eslint-enable no-await-in-loop */

    return { organizationID, classrooms }
})

export const createClassroom = createAsyncThunk<
    {
        organizationID: string,
        classroom: Classroom,
    },
    {
        mutationVariables: CreateClassroomMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/createClassroom', async ({ mutationVariables }, { dispatch, getState }) => {
    const { organizationID, data, status } = mutationVariables

    const variables: CreateClassroomMutationVariables = {
        organizationID,
        data,
        status
    }

    const response: GraphQLResult<CreateClassroomMutation> = await graphQLClient.graphql<GraphQLQuery<CreateClassroomMutation>>({
        query: mutations.createClassroom,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const classroom = response.data.createClassroom
    return { organizationID, classroom }
})

export const updateClassroom = createAsyncThunk<
    {
        organizationID: string,
        classroom: Classroom,
    },
    {
        organizationID: string,
        mutationVariables: UpdateClassroomMutationVariables,
    },
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('schoolManagement/updateClassroom', async ({ organizationID, mutationVariables }, { dispatch, getState }) => {
    const { id, data, status } = mutationVariables

    const variables = {
        id,
        data,
        status
    }

    const response: GraphQLResult<UpdateClassroomMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateClassroomMutation>>({
        query: mutations.updateClassroom,
        variables
    });

    if (response.errors) {
        throw new Error(JSON.stringify(response.errors))
    }

    const classroom = response.data.updateClassroom
    return { organizationID, classroom }
})

export const listenerMiddleware = createListenerMiddleware();

export const { updateHasFetchedCurrentUsersAllClasses, saveScratchAccounts, deleteClassFromOrganization } = schoolManagementSlice.actions

export default schoolManagementSlice.reducer
