import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { CreateGenAIAssistantCustomMutation, CreateGenAIAssistantCustomMutationVariables, GenAIAssistant, KnowledgeBase, UpdateGenAIAssistantMutation, UpdateGenAIAssistantMutationVariables } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const updateGenAIAssistant = createAsyncThunk<
    {
        organizationID: string,
        updatedGenAIAssistant: GenAIAssistant,
        updatedKnowledgeBases: KnowledgeBase[]
    }, // output
    {
        updateGenAIAssistantVariables: UpdateGenAIAssistantMutationVariables
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/updateGenAIAssistant', async ({ updateGenAIAssistantVariables }, { dispatch, getState }) => {
    const { currentSelectedOrganizationID } = getState().authentication
    const response: GraphQLResult<UpdateGenAIAssistantMutation> = await graphQLClient.graphql<GraphQLQuery<UpdateGenAIAssistantMutation>>({
        query: mutations.updateGenAIAssistant,
        variables: updateGenAIAssistantVariables
    });
    if (response.errors) {
        throw new Error("Failed to update knowledge base")
    }
    return {
        organizationID: currentSelectedOrganizationID!!,
        updatedGenAIAssistant: response.data.updateGenAIAssistant.genAIAssistant,
        updatedKnowledgeBases: response.data.updateGenAIAssistant.knowledgeBases
    }
})