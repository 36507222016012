import { createAsyncThunk } from '@reduxjs/toolkit'
import { OwnerType, MachineLearningModelType, MachineLearningProject, ListMachineLearningProjectsByIdAndModelTypeQueryVariables, ListMachineLearningProjectsByIdAndModelTypeQuery } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from '@aws-amplify/api';
import * as queries from 'src/globalUtils/graphql/queries';
import { RootState, AppDispatch } from '../store'
import { graphQLClient } from '../util'

type FetchMachineLearningProjectsProps = {
    ownerID: string,
}

export const fetchMachineLearningProjects = createAsyncThunk<
    MachineLearningProject[] | undefined,
    FetchMachineLearningProjectsProps, // input argument - projectName
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('machineLearningProjects/fetchMachineLearningProjects', async ({ ownerID }: FetchMachineLearningProjectsProps, { dispatch, getState }) => {
    // Skip. Already fetching
    if (getState().machineLearningProjects.userProjects) {
        return undefined
    }

    let shouldFetch = true
    let nextToken = null
    let projects: MachineLearningProject[] = []

    /* eslint-disable no-await-in-loop */
    while (shouldFetch) {
        const variables: ListMachineLearningProjectsByIdAndModelTypeQueryVariables = {
            ownerID,
            nextToken
        }
        // const user = await Auth.currentAuthenticatedUser() as CognitoUser
        const response: GraphQLResult<ListMachineLearningProjectsByIdAndModelTypeQuery> = await graphQLClient.graphql<GraphQLQuery<ListMachineLearningProjectsByIdAndModelTypeQuery>>({
            query: queries.listMachineLearningProjectsByIdAndModelType,
            variables
        });
        nextToken = response.data?.listMachineLearningProjectsByIdAndModelType?.nextToken
        shouldFetch = nextToken !== null && nextToken !== undefined
        projects = projects.concat(response.data!!.listMachineLearningProjectsByIdAndModelType!!.items!! as MachineLearningProject[])
    }

    return projects
})

export default fetchMachineLearningProjects