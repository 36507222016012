import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppDispatch, RootState } from "../../store"
import { DeleteGenAIAssistantsMutation, DeleteGenAIAssistantsMutationVariables, GenAIAssistant, KnowledgeBase } from "src/globalUtils/API"
import { GraphQLQuery, GraphQLResult } from "aws-amplify/api"
import * as mutations from 'src/globalUtils/graphql/mutations';
import { graphQLClient } from "../../util";

export const deleteGenAIAssistants = createAsyncThunk<
    {
        organizationID: string,
        deletedGenAIAssistantIDs: string[],
        updatedKnowledgeBases: KnowledgeBase[]
    }, // output
    {
        genAIAssistantIDsToDelete: string[],
    }, // input argument
    {
        dispatch: AppDispatch,
        state: RootState
    }
>('robotConcierge/deleteGenAIAssistants', async ({ genAIAssistantIDsToDelete }, { dispatch, getState }) => {
    const organizationID = (getState().authentication.currentSelectedOrganizationID!!)
    const variables: DeleteGenAIAssistantsMutationVariables = {
        ids: genAIAssistantIDsToDelete
    }
    const response: GraphQLResult<DeleteGenAIAssistantsMutation> = await graphQLClient.graphql<GraphQLQuery<DeleteGenAIAssistantsMutation>>({
        query: mutations.deleteGenAIAssistants,
        variables
    });
    if (response.errors || response.data.deleteGenAIAssistants.errorMessage) {
        throw new Error(response.data.deleteGenAIAssistants.errorMessage ?? "Failed to Delete GenAI Assistants: " + genAIAssistantIDsToDelete)
    }

    return {
        organizationID,
        deletedGenAIAssistantIDs: genAIAssistantIDsToDelete,
        updatedKnowledgeBases: response.data.deleteGenAIAssistants.updatedKnowledgeBases
    }
})