import { useState, useEffect, useMemo } from 'react';
import { S3File } from "src/globalUtils/API";
import { LocalFile, getFilesFromLocalOrRemote } from 'src/fileStorage/fileManager';

export function useFetchFiles(s3FilesToFetch: S3File[]): LocalFile[] {
  const [fileUrls, setFileUrls] = useState<LocalFile[]>([]);

  // Memoize the s3Keys array to prevent unnecessary re-renders
  const memoizedS3Files = useMemo(() => s3FilesToFetch,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(s3FilesToFetch)]);

  useEffect(() => {
    const fetchFiles = async () => {
      if (memoizedS3Files.length > 0) {
        try {
          const files = await getFilesFromLocalOrRemote({ s3Files: memoizedS3Files });
          setFileUrls(files);
        } catch (error) {
          console.error('Error fetching files:', error);
          throw error
        }
      }
    };

    fetchFiles();
  }, [memoizedS3Files]);

  return fileUrls;
}