import { FileCategory } from "src/globalUtils/API"

export interface ProjectFile {
    localUrl: string,
    file: File,
    fileCategory: FileCategory
}

// key == fileName + fileCategory
const projectFiles: Map<string, ProjectFile> = new Map()

function createKey(fileName: string, fileCategory: FileCategory) {
    return `${fileName}|${fileCategory}`
}

// TODO if this fails, you should trigger a load project action from blocklyProjectsSlice.tsx
function getFileFromProject(fileName: string, fileCategory: FileCategory): ProjectFile | undefined {
    return projectFiles.get(createKey(fileName, fileCategory))
}

function addProjectFile(fileToAdd: File, fileCategory: FileCategory, localURL?: string) {
    projectFiles.set(createKey(fileToAdd.name, fileCategory), {
        localUrl: localURL ?? URL.createObjectURL(fileToAdd),
        file: fileToAdd,
        fileCategory
    })
}

// file name should always be unique per category
function removeProjectFile(fileToRemove: string, fileCategory: FileCategory) {
    // release resource
    URL.revokeObjectURL(getFileFromProject(fileToRemove, fileCategory)!!.localUrl)
    projectFiles.delete(fileToRemove)
}

export { getFileFromProject, addProjectFile, removeProjectFile };